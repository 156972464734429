import React, {useEffect } from 'react';
import styled from 'styled-components';
import { NAVBAR_HEIGHT } from "components/constants";

import { useAppNavBarContext } from "context/AppNavBarContext"
import MobileSearchContainerComponenet from './MobileSearchContainerComponent';

//inputs
import LocationInput from 'apps/User/SearchComponents/LocationSearchComponent/LocationInput';
import DateTimeInput from 'apps/User/SearchComponents/DateTimeSearchComponent/DateTimeInput';
import DurationInput from 'apps/User/SearchComponents/DurationSearchComponent/DurationInput';

import SearchFunctions from 'utility/searchFunctions';
import { useSearch } from 'third_party/redux/selectors';
import { useSearchActions } from 'third_party/redux/search/searchActions';


const MobileSearchContainer = ({isVisible, ...props}) => {

    // const {closeSearchInputsViews, closeExpandedSearchEditMode, toggleLocationSearch, toggleWhenSearch, toggleDurationSearch, isLocationSearchVisible, isWhenSearchVisible, isDurationSearchVisible } = useAppNavBarContext()
    const {setActiveSearchInput,activeSearchInput,setSearchBarState,resetContext} = useAppNavBarContext()
    const searchCriteria = useSearch()?.current || {}; // Safe fallback to an empty object
    const tempSearchCriteria = useSearch().temp
    const {SEARCH_LOCATION, SEARCH_UPDATE} = useSearchActions()

    const submitSearch = () =>{
        console.log("submitted search")
        // closeExpandedSearchEditMode()
        setSearchBarState('compact')
        SEARCH_UPDATE(tempSearchCriteria)
    }
    const setLocation = (place) => {
        SEARCH_LOCATION(place)
        // closeSearchInputsViews()
        setActiveSearchInput(null)
      }
      console.log('1. tempSearchCriteria: ', tempSearchCriteria)

      const handleToggleState = (event,input) => {
        event.stopPropagation(); // Prevent event propagation

        setActiveSearchInput(input)
      }
    return (
        <MobileSearchFlexContainer $isVisible={isVisible} {...props}>
            <Header>
                <button onClick={resetContext}><p>x</p></button>
            </Header>
            <Content>
                <MobileSearchContainerComponenet 
                    leftText='where'
                    rightText={SearchFunctions.getLine1FromPlace(tempSearchCriteria.location)}
                    toggleState = {(event) => handleToggleState(event,'location')}
                    isExpanded={activeSearchInput===('location')}
                >
                    <LocationInput isOpen={activeSearchInput==='location'} setLocation={setLocation}/>
                </MobileSearchContainerComponenet>
                <MobileSearchContainerComponenet
                    leftText='when'
                    rightText={SearchFunctions.displayAbbreviatedMonthDayTime(tempSearchCriteria.date)}
                    toggleState = {(event) => handleToggleState(event,'when')}
                    isExpanded={activeSearchInput==='when'}
                    >
                    <DateTimeInput isOpen={activeSearchInput==='when'} />
                </MobileSearchContainerComponenet>
                <MobileSearchContainerComponenet
                    leftText="duration"
                    rightText={SearchFunctions.displayDuration(tempSearchCriteria.duration)}
                    toggleState = {(event) => handleToggleState(event,'duration')}
                    isExpanded={activeSearchInput==='duration'}
                    >
                    <DurationInput isOpen={activeSearchInput==='duration'}/>
                </MobileSearchContainerComponenet>
            </Content>
            <Footer>
                <SearchButtonContainer>
                    <SearchButton onClick={submitSearch}>
                        Search
                        {/* <img src="/images/searchicon.png" alt="searchicon" /> */}
                    </SearchButton>
                </SearchButtonContainer>
            </Footer>
        </MobileSearchFlexContainer>
    )
}
export default MobileSearchContainer

const MobileSearchFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px;
    right: 0%;  // Update the initial position to 0px
    width: 100%;
    min-width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: transparent;
    transform: ${(props) => (props.$isVisible ? 'translateX(0)' : 'translateX(100vw)')};  // Update the transform property
    transition: transform 0.3s ease-in-out;
    z-index: 5789789;
    align-items: end;
    background: white;
    color: black;
`;

const Header = styled.div`
display: flex;
flex-direction: row;
height: ${NAVBAR_HEIGHT};
width: 100%;
flex-grow: 0;
flex-shrink: 0;
// background-color: #CCC;
align-items: center;
justify-content: center;
text-align: center;

button {

    background-color: white !important;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    font-weight: 300;
    font-size: 1.0em;
    border: 1px solid #CCC;
    cursor: pointer;
    color: black;

    &:hover {
        background-color: #000000;
    }
        p{
            margin: 0px;
            padding: 0px;
            text-align: center;
        }
}

`

const Content = styled.div`
display: flex;
flex-grow: 1;
flex-shrink: 1;
position: relative;
max-height: 100%;
width: 100%;
flex-direction: column;
background-color: white;
padding: 5px 15px;
gap: 15px;

`

//FOOTER
const Footer = styled.div`
display: flex;
flex-direction: row;
height: ${NAVBAR_HEIGHT};
width: 100%;
flex-grow: 0;
flex-shrink: 1;
background-color: white;
padding: 5px 15px;
justify-content: space-around;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.25);
`

const SearchButtonContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
// min-width: calc(100% / 6);
cursor: pointer;
align-items: center;
justify-content: center;

img {
    display: block;
    width:50%;
  }

`

const SearchButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: calc(${NAVBAR_HEIGHT});
width: calc(2 * ${NAVBAR_HEIGHT}); /*wasn't 2 */
background-color: rgba(251, 174, 98, 1.0);
border-radius: 25px; /*50%*/
border: none;
cursor: pointer;
color: white;
font-weight: 600;
&:hover {
    background-color: rgba(251, 174, 98, 0.6);
}
`