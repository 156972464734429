//Helmet
import { Helmet } from 'react-helmet-async'; //TODO , NEED TO REPLACE

//Providers

//Common Components
import { PageContainer } from "components/Page/Elements";
import {PageContentContainer} from "components/Page/Elements"
// import ImageGrid from 'components/ImageGrid/ImageGrid';
import ImageGridInteractive from 'components/ImageGrid/ImageGridInteractive';

// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex,mediumDisplayContentHorizontal} from 'components/emotionStyles'

import {useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from 'react-router-dom';

//this is to get access to popup menu
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

import './css/home-styles.css'; // Assuming your styles are defined here
import config from 'config'
import InteractiveImage from "components/InteractiveImage/InteractiveImage";
import FAQManager from "components/FAQComponents/FAQManager";
import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"

// import InputContainer from "components/Inputs/InputContainer"
import TextField from '@mui/material/TextField';

import { NAVBAR_HEIGHT } from "components/constants";
import { CALLTOACTION, SECTION } from "components/standard";

//JUST ADDED 
import NavBarLogout from "./header/NavBarLogout"
import useSmoothScroll from 'components/scroll/useHeavyScroll';

//NEW
import { useUserActions } from 'third_party/redux/user/userAction';
import { useAuthActions } from 'third_party/redux/auth/authActions';
import { useSearchActions } from 'third_party/redux/search/searchActions';
// import { useGuestLoginWithPlace } from 'third_party/redux/convenientFunctions';

import GoogleMapsFunctions from 'utility/googleMapsFunctions';
import { useUserServiceContext } from 'api/User/Deprecated/UserServiceAPIContext';

import SignUpComponent from './components/SignUpComponent/SignUpComponent';

import CheckoutSuccess from 'pages/book/checkoutcomponents/checkoutSuccess';

import { useScreenContext } from 'context/AppContext/ScreenContext';
import Signup from 'pages/signup/Signup';

import { motion, useScroll, useTransform } from 'framer-motion';
//Library

//TEXT IMPORT
import { FlipLink } from 'components/LinkSelectionAnimation';
import { GiHamburgerMenu } from 'react-icons/gi';  // Import the hamburger icon

import FooterLoggedOut from 'components/Footer/FooterLoggedOut';
//VARIABLES

import SearchFunctions from 'utility/searchFunctions';

import {useStateManagementFunctions} from 'third_party/redux/stateManagementFunctions';

const imageHotspots = [
    { x: 2150/4096, y: 750/4096, title: "Balloon Garland", description: "A festive balloon garland that brightens any event, installed by its creator to ensure a perfect setting for celebrations", image: "/images/cta-logo-one.png" },
    { x: 1750/4096, y: 2380/4096, title: "DJ", description: "A professional DJ setup tailored to your event, with the provider ensuring a seamless musical experience from start to finish.", image: "/images/cta-logo-one.png" },
    { x: 650/4096, y: 2150/4096, title: "Painting", description: "A striking painting that adds artistic flair to your space, delivered and positioned by the artist for maximum impact", image: "/images/cta-logo-one.png" },
    { x: 2600/4096, y: 2660/4096, title: "Floral Arrangement", description: "Elegant floral arrangements that transform your event, meticulously arranged and set up by the florist.", image: "/images/cta-logo-one.png" },
    { x: 3400/4096, y: 2100/4096, title: "Selfie Wall", description: "An engaging selfie wall designed for memorable moments, curated and installed to enhance your event's social media appeal.", image: "/images/cta-logo-one.png" },
  ];

  const questionsAndAnswers = [
    { question: 'What is your return policy?', answer: 'Our return policy is ...' },
    { question: 'How long does shipping take?', answer: 'Shipping takes ...' },
    { question: 'Where are you located?', answer: 'We are located in ...' },
    // Add more questions and answers as needed
  ];



  const events = [
    'Charlotte, NC', 'Washington, DC', 'Chicago, IL', 'New York City, NY',
    'Miami, FL', 'Boston, MA', 'Atlanta, GA', 'Los Angeles, CA','Raleigh, NC','Richmond, VA','Charleston, SC'
  ];

  //Page 1 Layout (4 Rows x 3 Columns = 12 Slots)
  const images1 = [
    { src: 'images/Home/homegallery/i-v-mobilebar.jpeg',caption:'Mobile Bar', gridColumn:' 2 / span 1', gridRow:'1 / span 2'}, // Larger image
    { src: 'images/Home/homegallery/i-h-decorsetup.jpeg',caption:'Balloon Decor', gridColumn: '2/ span 2', gridRow: '3 / span 2' },
    { src: 'images/Home/homegallery/v-v-violinistmiami.mp4',caption:'Violinist', gridColumn: '1 / span 1', gridRow: '1 / span 4' },
    { src: 'images/Home/homegallery/v-v-flowers1.mp4',caption:'Flowers', gridColumn: '3 / span 1', gridRow: '1 / span 2' },
  ];
  
  const images1Mobile = [
    { src: 'images/Home/homegallery/i-v-mobilebar.jpeg',caption:'Mobile Bar', gridColumn:' 3 / span 1', gridRow:'1 / span 2'}, // Larger image
    // { src: 'images/Home/homegallery/i-h-decorsetup.jpeg',caption:'photo 1', gridColumn: '2/ span 2', gridRow: '3 / span 2' },
    { src: 'images/Home/homegallery/v-v-violinistmiami.mp4',caption:'Violinist', gridColumn: '1 / span 2', gridRow: '1 / span 4' },
    { src: 'images/Home/homegallery/v-v-flowers1.mp4',caption:'Flowers', gridColumn: '3 / span 1', gridRow: '3 / span 2' },
  ];
  //Page 2 Layout (4 Rows x 4 Columns = 12 Slots)

  const images2 = [
    { src: 'images/Home/homegallery/i-h-balloongarland2.jpeg',caption:'Balloon Arch', gridColumn:' 1 / span 2', gridRow:'1 / span 2'}, // Larger image
    { src: 'images/Home/homegallery/i-h-flowers2.jpeg',caption:'Decorative Flowers', gridColumn: '2/ span 3', gridRow: '3 / span 2' },
    { src: 'images/Home/homegallery/v-v-djrestaurant.mp4',caption:'DJ', gridColumn: '1 / span 1', gridRow: '3 / span 2' },
    { src: 'images/Home/homegallery/v-h-trumpet.mp4',caption:'Musician (Accoustic)', gridColumn: '3 / span 2', gridRow: '1 / span 2' },
  ];

  const images2Mobile = [
    { src: 'images/Home/homegallery/i-h-balloongarland2.jpeg',caption:'Balloon Arch', gridColumn:' 1 / span 2', gridRow:'1 / span 2'}, // Larger image
    { src: 'images/Home/homegallery/i-h-flowers2.jpeg',caption:'Flowers', gridColumn: '4/ span 1', gridRow: '3 / span 2' },
    { src: 'images/Home/homegallery/v-v-djrestaurant.mp4',caption:'DJ', gridColumn: '1 / span 3', gridRow: '3 / span 2' },
    { src: 'images/Home/homegallery/v-h-trumpet.mp4',caption:'Musician', gridColumn: '3 / span 2', gridRow: '1 / span 2' },
  ];
 
  

  //Page 3 Layout (4 Rows x 4 Columns = 15 Slots)
  const images3 = [
    { src: 'images/Home/homegallery/v-h-mariachi2.mp4',caption:'Mariachi Band', gridColumn:' 1 / span 3', gridRow:'1 / span 2'}, // Larger image
    { src: 'images/Home/homegallery/v-v-bartenders.mp4',caption:'Bartenders', gridColumn: '1/ span 1', gridRow: '3 / span 2' },
    { src: 'images/Home/homegallery/i-h-sax.jpeg',caption:'Saxophonist', gridColumn: '2 / span 3', gridRow: '3 / span 2' },
    { src: 'images/Home/homegallery/i-v-balloongarland.jpg',caption:'Balloon Garland', gridColumn: '4 / span 1', gridRow: '1 / span 2' },
  ];
 
  
//   import { ReactComponent as GoghNowLogo } from 'images/cta-logo-one-wht.svg';
//   import { ReactComponent as GoghNowLogoSM } from 'images/cta-logo-oneSM.svg';
  
  
//ELEMENT
const Home = (props) => {

    useSmoothScroll(); // You can change the scroll speed here


      //contentRef
    //   const contentRef = useRef();
      const GoghExperienceRef = useRef();

      const UserSectionRef = useRef();
      const ProviderSectionRef = useRef();
      const BusinessSectionRef = useRef();

    //navigation
    const navigate = useNavigate();
    const location = useLocation();
    
    const scrollToTop =() =>{
        console.log("this is working?")
        if (HeroSectionRef.current) {
            HeroSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
    } 

    const scrollToExperienceTop =() =>{
        console.log("this is working?")
        if (SectionHeroDividerSectionRefText.current) {
            SectionHeroDividerSectionRefText.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
    } 

    const scrollToUserSection =() =>{
        console.log("this is working?")
        if (UserSectionRef.current) {
            UserSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setHoveredItem(null);
            setServicesPopupMenuIsOpen(false)
          }
    } 
    const scrollToProviderSection =() =>{
        console.log("this is working?")
        if (ProviderSectionRef.current) {
            ProviderSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setHoveredItem(null);
            setServicesPopupMenuIsOpen(false)
          }
    } 
    const scrollToBusinessSection =() =>{
        console.log("this is working?")
        if (BusinessSectionRef.current) {
            BusinessSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setHoveredItem(null);
            setServicesPopupMenuIsOpen(false)
          }
    } 

  

    //Context
    //NEW USE & AUTH
    const {USER_UPDATE} = useUserActions()
    const {AUTH_LOGIN_GUEST} = useAuthActions()
    const {SEARCH_UPDATE} = useSearchActions()
    // const loginAsGuestWithPlace = useGuestLoginWithPlace()

    const {isMenuOpen, toggleMenu, closeMenu} = useGeneralSignUpMenuContext()
    const {emailSignup} = useUserServiceContext()
    //-Context: Google Maps

    //VARIABLES
    const searchInput = useRef();
    const [isFocused, setIsFocused] = useState(false);

    
    const {isMobile, isMedium, isLarge} = useScreenContext()


  
      
      //KEEP THIS BROTHER

      const handleProviderJoinClick = () => {
        navigate('/Provider'); //Animate the Join Now
      };

// Function to handle search submit
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const {updateSearchLocation} = useStateManagementFunctions()

  const handleSearchSubmit = async (address) => {

    console.log("Get Place From Address: ", address)
    if(!address){
      console.log('no address was input')
      return
    }
    try{
        const place = await GoogleMapsFunctions.getPlaceFromAddress({address:address});
        if (place){
            console.log('handleSearchSubmit (place returned is):', place);
            updateSearchLocation(place)
            navigate('/s')
        }
        else {
            //do nothing
            console.log("no place was found")
        }
    }
    catch(error){
        console.error('Error fetching place details:', error);
    }
  };

  // Function to handle place selection
  const handlePlaceSelected = (place) => {
    console.log('Place Was Selected:', place);
    updateSearchLocation(place)
    navigate('/s')
};

// const LOGIN_AS_GUEST_WITH_PLACE = (place) => {
//     const userUpdate = {lat:place.lat, lng:place.lng}
//     USER_UPDATE(userUpdate)
//     SEARCH_UPDATE({location:place})
//     AUTH_LOGIN_GUEST() //logs in
// }


//ALL SHIT
const [error, setError] = useState(false);
const formRef = useRef(null)
const [data, setData] = useState({})
const [showSuccessPopup, setShowSuccessPopup] = useState(false)

const [isSubmitting, setIsSubmitting] = useState(false)
const [status, setStatus] = useState('')


    const onSignupSubmit = async(data) => {


        setIsSubmitting(true)        
        console.log('Form is  valid. and we should submit: ', data);

        try{
            //data is being submitted
            const result = await emailSignup(data);
            console.log('submit email:', result);
            setShowSuccessPopup(true);
            setIsSubmitting(false);        
            setStatus('success');
        }
        catch (error){
            setIsSubmitting(false)        
            setStatus('error: ',error)

            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            //error.message is how you access it
        }

    };

    const submitEmail = async () => {
        try{
            //data is being submitted
            const result = await emailSignup(data)
            console.log('submit email:', result);
            setShowSuccessPopup(true);
        }
        catch (error){
            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            //error.message is how you access it
        }
      };

      const navigateToBusinessPage = () => {
        navigate('/business');
      }

      const navigateToProviderPage = () => {
        navigate('/provider');
      }

      const inputChange = (inputValue) => {
        console.log("new input: ",inputValue)
        GoogleMapsFunctions.fetchPredictions(inputValue)
        .then(predictions => {
          console.log('Predictions:', predictions);
        })
        .catch(error => {
          console.error('Error fetching predictions:', error);
        });
      }
    


      //=========================================
      //===============ANIMATION ITEMS===========
      //=========================================
      const [scrollPosition, setScrollPosition] = useState(0);

      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


      //FRAMER MOTION
      //==================

      //XXXXXXXXXXXXXXXXXXXXXXXXXX
      //XXXXXXXXX HERO REFXXXXXXXX
      const HeroSectionRef = useRef(null);
      const { scrollYProgress : scrollYProgressHeroSection } = useScroll({
        target: HeroSectionRef,
        offset:["start start" , "end center"],
      })

      const scale = useTransform(scrollYProgressHeroSection, [0,1],[1,0.85])
      const opacity = useTransform(scrollYProgressHeroSection, [0,1],[1,0])

          //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX Services Section XXXXXXXX

    const SectionHeroDividerSectionRef = useRef(null);
    const SectionHeroDividerSectionRefText = useRef(null);

    const { scrollYProgress : scrollYProgressSectionHeroDividerSection } = useScroll({
      target: SectionHeroDividerSectionRef,
      offset:["start start" , "end end"],
    })

    const opacitySectionHeroDivider = useTransform(scrollYProgressSectionHeroDividerSection, [0,0.5],[1,0])




      //XXXXXXXXXXXXXXXXXXXXXXXXXX
      //XXXXXXX EXPERIENCE REFXXXX
        // Use Framer Motion's scroll progress
        const GoghExperienceScrollRef = useRef(null);
        const { scrollYProgress : scrollYProgressExperienceSection } = useScroll({
            target: GoghExperienceScrollRef,
            offset: ["start start", "end start"],
        });

        // Map vertical scroll progress to horizontal translation for container
        const translateX = useTransform(scrollYProgressExperienceSection, [0, 1], ["150dvw", "-300dvw"]);


    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX Services Section XXXXXXXX
      const SectionDividerSectionRef = useRef(null);
      const { scrollYProgress : scrollYProgressSectionDividerSection } = useScroll({
        target: SectionDividerSectionRef,
        offset:["start start" , "end end"],
      })

      const opacitySectionDivider = useTransform(scrollYProgressSectionDividerSection, [0,0.5,1],[1,0,0])

          //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX WHERE Section XXXXXXXX
    const WhereDividerSectionRef = useRef(null);
    const { scrollYProgress : scrollYProgressWhereDividerSection } = useScroll({
      target: WhereDividerSectionRef,
      offset:["start start" , "end end"],
    })

    const opacityWhereDivider = useTransform(scrollYProgressWhereDividerSection, [0,1],[1,0])


   const [servicesPopupMenuIsOpen, setServicesPopupMenuIsOpen] = useState(false)
    const openMiniPopupMenu = () => {
        setServicesPopupMenuIsOpen(true)
    }
    const closeMiniPopupMenu = () => {
        setServicesPopupMenuIsOpen(false)
    }


//HOVE ITEMS
const [hoveredItem, setHoveredItem] = useState(null);
const [titleAnimation, setTitleAnimation] = useState(null)

  // Handle hover enter
//   const handleMouseEnterOfQuickMenu = (key) => {
//     setHoveredItem(key);
//     if (key === 'user') {
//         setTitleAnimation(css`${pulse} 3s infinite`);
//     }
//   };

//   // Handle hover leave
//   const handleMouseLeaveOfQuickMenu = () => {
//     setHoveredItem(null);
//     setTitleAnimation(null)
//     console.log("HOVERING JOINT IS NULL")
//   };
    // Dynamically set the text based on hoveredItem
    // const getHoverText = () => {
    //     switch (hoveredItem) {
    //       case 'user':
    //         return 'Find and book services';
    //       case 'provider':
    //         return 'Offer your services and grow your business';
    //       case 'business':
    //         return 'Partner with us for exclusive benefits';
    //       default:
    //         return (
    //             <>
    //               {'  '}
    //               <br />
    //             </>
    //           );
    //     }
    //   };

        const getHoverText = () => {
        switch (hoveredItem) {
          case 'user':
            return 'Find and book services';
          case 'provider':
            return 'Offer your services and grow your business';
          case 'business':
            return 'Partner with us for exclusive benefits';
          default:
            return (
                <>
                  {'  '}
                  <br />
                </>
              );
        }
      };





      const stickyRef = useRef(null);
      const [stickyHeight, setStickyHeight] = useState(0);
    
      useEffect(() => {
        if (!stickyRef.current) return;
    
        const observer = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setStickyHeight(entry.contentRect.height); // Update height on resize
          }
        });
    
        observer.observe(stickyRef.current);
    
        return () => {
          observer.disconnect(); // Clean up observer on unmount
        };
      }, [stickyRef]);


  const [isScrolledFromTop, setIsScrolledFromTop] = useState(false);
  const handleScrollStateChangeFromTop = (scrollState) => {
    setIsScrolledFromTop(scrollState);
    console.log('isScrolledAtTop: ',scrollState)

  };

   //COLOR SHIT
   const [highlightedUserType, setHighlightedUserType] = useState(''); // Set default color to transparent
   const [platformContainerColor, setPlatformContainerColor] = useState('transparent'); // Set default color to transparent
   const [platformContainerBackground, setPlatformContainerBackground] = useState(''); // Set default color to transparent

   const handleMouseEnter = (highlightedType) => {

    switch (highlightedType) {
      case 'user': 
        setHighlightedUserType('user')
        setPlatformContainerColor('#554B7020');
        setPlatformContainerBackground('linear-gradient(to bottom right, #554B70, transparent)');
        break;
      case 'provider':
        setHighlightedUserType('provider')
        setPlatformContainerColor('#64B0B820');
        setPlatformContainerBackground('linear-gradient(to bottom, #64B0B8, transparent)');

        break;
      case 'business':
        setHighlightedUserType('business')
        setPlatformContainerColor('#E0CAA420');
        setPlatformContainerBackground('linear-gradient(to  bottom left, #E0CAA4, transparent)');
        break;
      default:
        setPlatformContainerColor('transparent');
        setPlatformContainerBackground('');
        break;
    }
  };

  const handleMouseLeave = () => {
    setPlatformContainerColor('transparent'); // Reset color on mouse leave
    setPlatformContainerBackground('');
    setHighlightedUserType('')
  };

return (
    <>
    <Helmet>
        <title>GoghNow | Explore Event Services Near You</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="GoghNow | The Future of Events" />
        <meta name="description" content="GoghNow is a one-stop-shop for event services" />
        <meta property="og:image" content="images/cta-logo-one.png" />
    </Helmet>
    <SpecialNavBarLogout handleNavigateHome={scrollToTop} isScrolledFromTop={isScrolledFromTop}/>
    <SpecialPageContentContainer onScrollStateChangeFromTop={handleScrollStateChangeFromTop}>
        
        {/* <GoghNowHeroSection> */}
            <HeroSection ref={HeroSectionRef} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                <HeroSectionImage/>
                {/* <GoghNowServicesTint $gradient={'linear-gradient(to top, #00000005,#00000010, transparent)'}/> */}
                {/* $gradient={'linear-gradient(to top, #000000,#00000010, transparent)'} */}
            {/* {titleAnimation ? (
                        <HeroTextAnimated style={{ scale, opacity }}>
                        <span>Experience Your Space</span>
                        </HeroTextAnimated>
                        ) : (
                        <HeroText style={{ scale, opacity }}>
                        <span>Experience Your Space</span>
                        </HeroText>
                    )} */}
                <StickyDiv ref={stickyRef} stickyHeight={stickyHeight} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    <StickyDivFlexContent $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <HeroTextRegular style={{scale,opacity }}>the modern marketplace for event services</HeroTextRegular>
                        {isLarge && (
                        <HeroDescription style={{scale,opacity }}>unique local vendors and à la carte services on-demand</HeroDescription>
                        )}
                        {!isLarge && (
                        <HeroDescription style={{scale,opacity }}>unique local vendors on-demand</HeroDescription>
                        )}
                        <SpecialAddressSearchComponent onSearchSubmit={handleSearchSubmit} onPlaceSelected={handlePlaceSelected} onChange={inputChange}/>
                    </StickyDivFlexContent>
                </StickyDiv>
            </HeroSection>
        {/* </GoghNowHeroSection> */}




        {isLarge && (
            <ServicesSection ref={SectionDividerSectionRef}>
                <ServicesOverlay style={{opacity: opacitySectionDivider}}></ServicesOverlay>
                <ServicesContainer>
                    <ServicesHeadingExpanded>Create Event Experiences in<br/><b>Just a Few Clicks</b></ServicesHeadingExpanded>
                </ServicesContainer>
            </ServicesSection>
        )}
                    {/* <ServicesDescription>Become a part of GoghNow</ServicesDescription> */}
                    {/* <ServicePopupMenuMiniContainer>
                        <CTAPopUpMenuItem onClick={scrollToUserSection}  $borderColor={'#FBAE62'} $textColor={'#FBAE62'} $hoverColor={'#FBAE62'}>Users</CTAPopUpMenuItem>
                        <CTAPopUpMenuItem onClick={scrollToProviderSection} $borderColor={'#E0CAA4'} $textColor={'#E0CAA4'} $hoverColor={'#E0CAA4'}>Providers</CTAPopUpMenuItem>
                        <CTAPopUpMenuItem onClick={scrollToBusinessSection}  $borderColor={'#554B70'} $textColor={'#554B70'} $hoverColor={'#554B70'}>Businesses</CTAPopUpMenuItem>
                    </ServicePopupMenuMiniContainer> */}

        <GoghNowSection $backgroundColor={'white'}>
            <GoghNowServicesTint $backgroundColor={platformContainerColor} $gradient={platformContainerBackground}/>

            <GoghNowGridContainer>

                <ServicesHeading>EXPLORE OUR PLATFORM</ServicesHeading>
            
                <GridContainerServices $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>




                    <GridItemService $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} $highlightedUserType={highlightedUserType} $sectionType={'user'}>
                        <GridImageContainer onClick={scrollToTop} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onMouseEnter={() => handleMouseEnter('user')} onMouseLeave={handleMouseLeave}>
                            <ImageContainer src='/images/Home/createanexperience.png'  alt="business"/>
                        </GridImageContainer>
                        <GridContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onMouseEnter={() => handleMouseEnter('user')} onMouseLeave={handleMouseLeave}>
                            <FlexGroupContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                <GoghNowHeaderType $textColor={'#000000'}>CREATE AN EXPERIENCE</GoghNowHeaderType>
                                <GoghNowDescription>Select the vendors you need to create your event experience.</GoghNowDescription>
                            </FlexGroupContainer>
                            <OfferingLink onClick={scrollToTop}>Try it now →</OfferingLink>
                        </GridContentContainer>
                    </GridItemService>



                    <GridItemService $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} $highlightedUserType={highlightedUserType} $sectionType={'provider'}>
                        <GridImageContainer onClick={navigateToProviderPage} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onMouseEnter={() => handleMouseEnter('provider')} onMouseLeave={handleMouseLeave}>
                            <ImageContainer src='/images/Home/pimage7.png' alt="provider" />
                        </GridImageContainer>
                        <GridContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onMouseEnter={() => handleMouseEnter('provider')} onMouseLeave={handleMouseLeave}>
                            <FlexGroupContainer>
                                <GoghNowHeaderType $textColor={'#000000'}>PROVIDE A SERVICE</GoghNowHeaderType>
                                <GoghNowDescription>Provide event services, or rent out your event products or decor.</GoghNowDescription>
                            </FlexGroupContainer>
                            <OfferingLink onClick={navigateToProviderPage}>Learn more →</OfferingLink>
                        </GridContentContainer>

                    </GridItemService>




                    <GridItemService $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} $highlightedUserType={highlightedUserType} $sectionType={'business'}>
                        <GridImageContainer onClick={navigateToBusinessPage} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onMouseEnter={() => handleMouseEnter('business')} onMouseLeave={handleMouseLeave}>
                            <ImageContainer src='/images/Home/bimage6.png'  alt="business"/>
                        </GridImageContainer>

                        <GridContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onMouseEnter={() => handleMouseEnter('business')} onMouseLeave={handleMouseLeave}>
                            <FlexGroupContainer>   
                                <GoghNowHeaderType $textColor={'#000000'}>ELEVATE YOUR BUSINESS</GoghNowHeaderType>
                                <GoghNowDescription>Simplify the booking of your space and offer clients a complete suite of event services, all in one seamless experience.</GoghNowDescription>
                            </FlexGroupContainer>
                            <OfferingLink onClick={navigateToBusinessPage}>Learn more →</OfferingLink>
                        </GridContentContainer>
                    </GridItemService>




                </GridContainerServices>
            </GoghNowGridContainer>
        </GoghNowSection>




        {isLarge && (
        <HeroTransitionSection ref={SectionHeroDividerSectionRef}>
            <HeroTransitionOverlay style={{opacity: opacitySectionHeroDivider}}></HeroTransitionOverlay>
            <HeroTransitionContainer>
                <HeroTransitionHeadingExpanded ref={SectionHeroDividerSectionRefText}>Indulge In Different</HeroTransitionHeadingExpanded>
                {/* <HeroTransitionDescription>Discover User-Created Events</HeroTransitionDescription> */}
            </HeroTransitionContainer>
        </HeroTransitionSection>
        )}


        <GoghNowSection ref={GoghExperienceRef} $backgroundColor={'black'}>
            <GoghNowExperienceContainer ref={GoghExperienceScrollRef}>
            {!isLarge && (
                <HeroTransitionHeading>Indulge In Different</HeroTransitionHeading>
            )}

                <GoghNowExperienceStickyDiv> 
                    <GoghNowExperienceStickyContainerInner style={{x:translateX}}>
                        <GoghNowExperienceGridItem>
                            <ImageGridInteractive items={isMedium ? images1Mobile : images1} columns="repeat(3, 1fr)" rows="repeat(4, 1fr)" gap="5px" layout="custom1" />
                        </GoghNowExperienceGridItem>
                        <GoghNowExperienceGridItem>
                            <SpecialImageGridInteractive items={isMedium ? images2Mobile : images2}columns="repeat(4, 1fr)" rows="repeat(4, 1fr)" gap="5px" layout="custom1" />
                        </GoghNowExperienceGridItem>
                        <GoghNowExperienceGridItem>
                            <SpecialImageGridInteractive items={images3} columns="repeat(4, 1fr)" rows="repeat(4, 1fr)" gap="5px" layout="custom1" />
                        </GoghNowExperienceGridItem>

                    </GoghNowExperienceStickyContainerInner>
                </GoghNowExperienceStickyDiv>
            </GoghNowExperienceContainer>
        </GoghNowSection>


        <GeneralSignUpMenu isOpen={isMenuOpen} onClose={closeMenu} items = {[]}/>

        <FooterLoggedOut></FooterLoggedOut>

    </SpecialPageContentContainer>

    </>
    )
}


//            {isMenuOpen && <GeneralSignUpMenu isOpen={isMenuOpen}/>} //this slides in

    //LOAD SCRI
 //======================================
////==========ANIMATION STUFF==========
//======================================
// const appear = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(-100px);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0px);
//   }
// `;

// const disappear = keyframes`
//   from {
//     opacity: 1;
//     transform: translateX(0px);
//   }
//   to {
//     opacity: 0;
//     transform: translateX(-100px);
//   }
// `;

const pulse = keyframes`
  0% {
    color: white;
  }
  50% {
    color: #FFA500; /* Slight variation for a smooth pulse effect */
  }
  100% {
    color: #FBAE62;
  }
`;




const SpecialNavBarLogout = styled(NavBarLogout)`
    position:fixed;
    // background-color: transparent !important;
`
const MobileContent = styled.div`
    ${mobileSizeDisplayFlex};
`
const DesktopContent = styled.div`
    ${mobileSizeDisplayNone};
    flex: 0 0 calc(50% - 25px);
`

const SpecialPageContentContainer = styled(PageContentContainer)`
background-color: #F5F5F580 !important;
`

const SpecialAddressSearchComponent = styled(AddressSearchComponent)`
// box-shadow: 5px 5px 10px #FBAE6270;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7); /* Optional styling for emphasis */

    max-width: 600px;

    input::placeholder {
    text-align: center; /* Ensures the placeholder is aligned to the padding */
    color: #CCC;
    padding-left: 80px;
}
`
const GoghNowHeroSection = styled.div`
    position:relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100vw;
    background-color: ${(props) => props.$backgroundColor || 'white'};  
    min-height: 100vh;
    overflow-y: auto; /* Allow scrolling */
`;

const HeroSection = styled.div`
  display: flex;
  position:relative;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: ${(props) => (props.$isLarge ? 'calc(100vw / (16/9))' : 'calc(100vw /  (2/3))')};
  background-color: black;
  overflow: visible;
//   background-image: url(${(props) => props.$backgroundImage || "/images/Home/userNew.png"});
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;

`;




// Define the keyframes for the pulsing effect
const pulseAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0);
  }
`;

const HeroSectionImage = styled.div`
position: absolute;
width: calc(100% + 15px);
height: 100%;
  background-image: url("/images/Home/userNew.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(0.7);
    animation: ${pulseAnimation} 7s ease-in-out infinite;
`

const StickyDiv = styled.div`
  display:flex;
  flex-direction:column;
  position: sticky;
//   top: ${(props) => `calc(100vh - ${props.stickyHeight}px)`};
  top: ${(props) => (props.$isLarge ? `calc(80vh - ${props.stickyHeight}px)`:`calc(75vh - ${props.stickyHeight}px)` )};


//   padding: 20px 0px 40px 0px;
//   margin: 20px 0px;
  z-index: 1; /* Ensure it's on top */
//   box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional styling for emphasis */
`;
const StickyDivFlexContent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: auto;
align-items: center;
// padding: 36px 36px;
text-align: center;
padding: ${props => {
    if (props.$isMobile) return '5px 5px';
    if (props.$isMedium) return '15px 15px';
    if (props.$isLarge) return '36px 36px';
    return '36px 36px'; // Default to large if none match
  }};
`

//Container joint
// const CTA = styled(CALLTOACTION)`
// padding-bottom: calc(${NAVBAR_HEIGHT} + ${NAVBAR_HEIGHT});
// display: flex;
// flex-direction: column;
// gap: 10px;
// top:0px;
// right:0px;
// width: 100vw;
// position: sticky;
// min-height:50vh;
// height: auto;
// align-items: center;
// justify-content: end;

//   p, h1{
//         margin: 0px;
//         }

// `;


const HeroSectionOverlay = styled.div`
display: flex;
flex-direction: column;
// justify-content: center;
align-items: center;
min-height: 100vh;
width: 100vw;
// background-color: #00000090; /*#FBAE6260 #AAAAAA90;*/
// background-image: url("/images/login-background.png");
// background-size: cover; /* Cover the entire section */
// background-position: center; /* Center the background image */
position:sticky;
`;




//CORRECT
const GoghExperienceCTA = styled(motion.div)`
display: flex;
flex-direction: column;
height: auto;
width: auto;
justify-content: center;
align-items: center;
cursor: pointer;

&:hover{
    h3{
    color: #FBAE62;
    }
}
`

const GoghExperienceCTAHeader = styled.h3`

margin: 0px;
padding: 0px;
color: #FBAE6280;
`

const HeroCellLogo  = styled(motion.h1)`
color: #FFFFFF80;
//   font-size: 6.5em;
//   font-weight: 600;
  font-weight: 200;
//   letter-spacing: 10px;  /* Adjust the value to control the spacing */

margin-top: 0px;
line-height: 95%;
text-shadow: 2px 2px 2px #00000060; /* Creates a white shadow */
margin-bottom: 0px;
span{
 
}
`


const HeroText = styled(motion.h1)`
  color: #00000015;
  font-size: 7rem;
//   font-weight: 600;
    font-weight: 200;
  letter-spacing: 10px;  /* Adjust the value to control the spacing */

  margin-top: 100px;
  line-height: 95%;
//   text-shadow: 2px 2px 2px #00000060; /* Creates a white shadow */
  margin-bottom: 0px;
  span{
   
}
`

const HeroTextRegular = styled(motion.h2)`
  color: #FFFFFF;
  margin-top: 0px;
    // letter-spacing: 10px;  /* Adjust the value to control the spacing */
    // font-size: 6.5em;
  line-height: 95%;
  text-shadow: 2px 2px 2px #00000060; /* Creates a white shadow */
      font-weight: 200;
//   margin-bottom: 10px;
margin-bottom: 0px;
  padding-bottom: 0px;
  font-size:clac(3vw + 1vh);
// animation: ${pulse} 3s infinite;
span{
    
}
`

const HeroDescription = styled(motion.p)`
color: #FBAE62;
font-size : 18px;
// margin: 0 0 24px;
line-height: 1.5em;
letter-spacing: 1.5px;
// max-width: 400px;
margin-bottom: 50px;
// font-weight: 600;
margin-top: 10px;


`

const ServicePopupMenuContainer = styled(motion.div)`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background-color: transparent;
    width: auto;
    auto: 50px;
    gap:20px;
    padding-top: 25px;
    `

    const ServicePopupMenuMiniContainer = styled(motion.div)`
        display:flex;
        justify-content: center;
        background-color: transparent;
        width: auto;
        auto: 50px;
        align-items: space-around;
        gap: 20px;
        `

        const ServicePopupMenuMiniContainerText = styled(motion.p)`
            color: #FFFFFF90;
            //   text-transform: uppercase;
              padding-top: 10px;
        `
    
const ClosePopupMini = styled.div`
display:flex;
justify-content: center;
align-items: center;
height: 35px;
width: 35px;
aspect-ratio: 1 / 1;
    border: 2px solid #AAA;
background-color: #3b3b3b;
border: solid 1px #3b3b3b;
border-radius: 50%;
cursor: pointer;
  &:hover{
        background-color: #AAA !important;  // Corrected the string interpolation
        border: solid 1px #AAA;
        color: white;
    }


`
const CTAPopUpMenuItem = motion(
    styled(FlipLink, {
      shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
    })`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 25px;
    width: auto;
    padding: 5px 25px;
    cursor: pointer;
    border: ${props => props.$border ? `${props.$border}` : '2px solid #64B0B8'};  // Corrected the string interpolation
    color: ${props => props.$color ? `${props.$color}` : '#64B0B8'};  // Corrected the string interpolation

    &:hover{
        background-color: ${props => props.$hoverColor ? `${props.$hoverColor}` : '#64B0B8'};  // Corrected the string interpolation
        color: white;
    }
    `)


    //HERO SECTION TRANSITION


const HeroTransitionSection = styled.div`
min-height:150dvh;
width: 100dvw;
background-color: black;
padding: 0px;
margin:0px;
`
const HeroTransitionOverlay = motion(styled.div`
position: absolute;
display: flex;
width: 100dvw;
height: 150dvh;
background: white;
opacity: 1;
padding: 0px;
margin:0px;
`)
const HeroTransitionContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100dvw;
min-height: 200dvh;
padding: 0px;
margin:0px;
background-color: transparent;
position: relative;
`

const HeroTransitionHeadingExpanded = styled.h1`
text-align: center;
color: #FFFFFF80;
margin: 10px;
padding: 100px 20px;
  text-transform: uppercase;


`

const HeroTransitionHeading = styled.h2`
text-align: center;
color: #FFFFFF80;
margin: 10px;
padding: 100px 20px;
  text-transform: uppercase;


`
const HeroTransitionDescription = styled.h2`
    color: #AAA;
    font-weight:300;
    text-align:center;
    margin: 5px;

`


//GOGHNOW USER EXPERIENCES
// Outer Section Container (Tall Scrollable Container)


// const GoghNowExperienceSection = motion(styled(SECTION)`
//     background-color: black;
//     width: 100%;
//     overflow-x: scroll; // Allow horizontal scrolling

//     min-height: 100vh;  // Enough scrollable height to trigger the horizontal scroll
//     height: auto;
//     scroll-snap-type: x mandatory;
//   `);

const GoghNowExperienceContainer = styled.div`
display: inline-block;
height: 400dvh;
width: 100dvw;
background-color: transparent; /*#f0f0f0;*/
padding-top: 0px;
top: 0px;
left: 0px;
`;

const GoghNowExperienceStickyDiv = styled.div`
  position: sticky;
  top: 0;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  overflow-x:hidden;
    background-color: transparent;

`;

  const GoghNowExperienceStickyContainerInner = motion(styled.div`
    display: flex;
    top: 0;
    left: 0;
    min-height: 100dvh; // Full viewport height
    width: 300dvw;  // Extra-wide for horizontal scrolling effect
    overflow-x: scrollable;
    background-color:transparent;
  `);

const GoghNowExperienceGridItem = styled.div`
    display: flex;
    position: relative;
    // justify-content: center;
    // align-items: center;
    width: 100dvw; // Full viewport width for each section
    height: 100dvh; // Full viewport height
    overflow: hidden;
    color: white;
    h2{
    color: white;
    }
    background-color: transparent; // Example background color for visual clarity
`

// const SpecialImageGrid = styled(ImageGrid)`

// background-color: #3F3F3F;
// gap: 10px;
// padding: 10px;
// `

const SpecialImageGridInteractive = styled(ImageGridInteractive)
  `
background-color: transparent;
// background-color: yellow;

`
const PhotoAlbumContainer = styled.div`
display: flex;
height: 33.3vh;
flex-grow: 1;
flex-shrink: 1;
gap: 15px;
`

const PhotoAlbumItem = styled.div`
display: flex;
padding: 10px;
background-color: #fcfcfc;
border: 1px solid #bebebe;
width: 367px;
height: 377px;
`
const PhotoContainer = styled.div`
display: flex;
height: 66.6%;
width: 100%;
background-color: #DDD;
`


//SECTION 2
//===================================
const UsersSection = styled(SECTION)`
display: flex;
flex-direction: row;
text-align: center;
width: 100%;
justify-content: center;
align-items: center;
background-color: black;
padding: 20px;
    h3{
        text-align: center !important;
    }
`

const UsersSectionContainer = styled(SECTION)`
${mobileDisplayContentVertical};

display: flex;
flex-direction:column;
width:auto;
padding: 25px 20px;
justify-content: space-around;
background-color: #EEE;
min-height: 50%;
// border-radius: 50px;
// max-width: 1080px;
`

const UsersHeroText = styled.h2 `

padding: 0px 0px;
text-align: left;
width: 100%;
margin: 0px;
text-align:center;
`

const UsersNumber = styled.h2 `

// padding: 10px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
width:${props => {
    if (props.$isMobile) return '120px';
    if (props.$isMedium) return '90px';
    if (props.$isLarge) return '60px';
    return '60px'; // Default to large if none match
  }};
height: 60px;
border-radius: 30px;
// aspect-ratio: 1 / 1;
margin: 0px;
background-color: transparent;
color:white;
text-align:center;
`


const UsersContainer = styled.div`

${mobileDisplayContentVertical};
display: grid;
grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(3, 1fr)';
    if (props.$isLarge) return 'repeat(6, 1fr)';
    return 'repeat(4, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
width: auto;
gap: 15px;
justify-content: space-around;
padding: 15px 0px;



@media (max-width: 769px) {
    gap: 0px;
    padding: 20px 0px;
}

@media (max-width: 450px) {
    gap: 0px;
    padding: 0px 0px;
}
`

const Users = styled.div`

display: flex;
flex-direction:${props => {
    if (props.$isMobile) return 'row';
    if (props.$isMedium) return 'row';
    if (props.$isLarge) return 'column';
    return 'column'; // Default to large if none match
  }};
justify-content: center;
align-items: center;
background-color: #EEE;
border: 1px solid #CCC;
padding: 15px;
    h4{
        padding-top: 0px;
    }
@media (max-width: 769px) {
    gap: 20px;
    // padding: 10px 10px;
}
    width: auto;
`

const UsersImageContainer = styled.div`
margin-bottom: 0px;

width: 50%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 1;
    // width: 50%;
    padding-left: 5px;
    padding-right:5px;
}
`

const UsersContentContainer = styled.div`
display: flex;
flex-direction:column;
width: 100%;
padding-left: 15px;
padding-right: 15px;
 @media (max-width: 769px) {
    text-align: center;
    padding-left: 5px;
    padding-right:5px;
    flex-grow: 1;

        p{
    margin: 0px;
    }
}
`

const UsersTitle = styled.h3`
color:#3F3F3F;
margin: 0px;
font-weight: 600;

`
const UsersDescription = styled.h4`
color:#3F3F3F;
padding-top: 10px;

// font-size: 18px;
font-weight: 200;
margin: 0px;

`

//===================================
//SECTION CITIES

const CitiesSection = styled(SECTION)`
// overflow: hidden;
display: flex;
flex-direction: column;
text-align: left;
// min-height: 100vh;
width: 100%;
// justify-content: start;
align-items: start;
background-color: #E0CAA4;/*#F5F5F5;*/
`

const CitiesHeroText = styled.h2 `

`

const CitiesSubHeroText = styled.h3 `
  color: black;
  font-size: 2.0em;
  font-weight: 200;
  margin-top: 0px;
  line-height: 100%;
//   text-shadow: 2px 2px 2px #00000060; /* Creates a white shadow */
padding: 0px 0px;
`

const CitiesContainer = styled.div`

${mobileDisplayContentVertical};
display: grid;
// grid-template-columns: repeat(4, 1fr);
grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(2, 1fr)';
    if (props.$isMedium) return 'repeat(3, 1fr)';
    if (props.$isLarge) return 'repeat(4, 1fr)';
    return 'repeat(4, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
max-width: 100%;
width: 100%;
height: auto;
min-height: 50vh;
gap: 25px;
justify-content: space-around;
padding: 75px 0px;
margin: 225px 0px;
// padding-right: 108px;
// padding-left: 108px;
z-index: 1;


`

const MapContainer = styled.div`
display:flex;
position: absolute;
justify-content: center;
align-items: center;
top: 0px;
left: 0px;
width:100%;
height: 300px;
opacity: 0.25;
// background-color: green;
min-height: 50vh;
z-index: 0;
img{
display:flex;
max-width:${props => {
    if (props.$isMobile) return '80%';
    if (props.$isMedium) return '75%';
    if (props.$isLarge) return '100%';
    return '100%'; // Default to large if none match
  }};
}
`

const GridItem = styled.div`
    background-color: transparent;
    color: black;
    padding: 15px;
    text-align: left;
    border: 1px solid #EEE;
    border-radius: 8px;
    background-color: #FFFFFF80;
  `
//===================================
//SECTION 3
const GoghNowSection = styled.div`
display: flex;
position: relative;
flex-direction: row;
text-align: center;
width: 100%;
justify-content: center;
align-items: center;
background-color: ${props => props.$backgroundColor ? `${props.$backgroundColor}` : 'white'};  // Corrected the string interpolation
min-height: 50vh;
padding-top: 48px;
padding-bottom:48px;
`

const ServicesSection = styled.div`
min-height:150dvh;
width: 100dvw;
background-color: #FFFFFF;
padding: 0px;
margin:0px;
`
const ServicesOverlay = motion(styled.div`
position: absolute;
display: flex;
width: 100dvw;
height: 150dvh;
background: black;
opacity: 1;
padding: 0px;
margin:0px;
`)
const ServicesContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100dvw;
min-height: 150dvh;
padding: 0px;
margin:0px;
background-color: transparent;
position: relative;
`


const GoghNowServicesTint = styled.div`
position: absolute;
display:block;
width: 100%;
height: 100%;
background-color: ${props => props.$backgroundColor ? `${props.$backgroundColor}` : 'transparent'};  // Corrected the string interpolation
background: ${props => props.$gradient}; /* Apply the gradient dynamically */
z-index:1;
`

const ServicesHeadingExpanded = styled.h1`
text-align: center;
color: #3F3F3F;
// margin-bottom: 15px;
padding: 100px 20px 100px 20px;
  text-transform: uppercase;

`

const ServicesHeading = styled.h2`
text-align: center;
color: #3F3F3F;
// margin-bottom: 15px;
padding: 100px 20px 100px 20px;
  text-transform: uppercase;

`
const ServicesDescription = styled.h2`
color: #3F3F3F60;
text-align: center;
`

//==================================
//===========WHERE SECTION==========
const WhereSection = styled.div`
min-height:200dvh;
width: 100dvw;
background-color: #E0CAA4;
padding: 0px;
margin:0px;
`
const WhereOverlay = motion(styled.div`
position: absolute;
display: flex;
width: 100dvw;
height: 200dvh;
background: #a11826;
opacity: 1;
padding: 0px;
margin:0px;
`)
const WhereContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100dvw;
min-height: 200dvh;
padding: 0px;
margin:0px;
background-color: transparent;
position: relative;
`

const WhereHeading = styled.h1`
text-align: center;
color: #FFFFFF;

`
const WhereDescription = styled.h2`

`




//NEW SHIT

const GoghNowGridContainer = styled.div`

display: flex;
flex-direction:column;
width:auto;
// padding: 25px 20px;
padding: 50px 20px 35vh 20px;

margin: ${(props) => {
    if (props.$isLarge) return '0px 64px';
    return '0px 0px'; // Default to large if none match
}};

justify-content: space-around;
// background-color: #F3DD93;
min-height: 100vh;
z-index:2;
`

const GridContainerServices = styled.div`
${mobileDisplayContentVertical};
  display: grid;
//   flex-direction:column;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-template-columns: ${(props) => {
        if (props.$isLarge) return 'repeat(3, 1fr)';
        return 'repeat(1, 1fr)'; // Default to large if none match
    }}; 
  grid-gap: 40px; /* Adjust gap between columns */
  width: auto;
  min-height: 100%;
align-items: start; /* Ensures items align to the top */
    justify-items: start; /* Aligns items consistently */
`;

const GridItemService = styled.div`
${mediumDisplayContentHorizontal};
  display: flex;
  flex-direction: column;
  height:100%;
//   width: 100%;
//   padding: 20px;
  text-align: center;
  justify-content: ${(props) => {
        if (props.$isLarge) return 'start';
        return 'left'; // Default to large if none match
    }}; 
      gap: ${(props) => {
        if (props.$isLarge) return '2rem';
        return '0px'; // Default to large if none match
    }}; 

opacity: ${(props) =>
    props.$highlightedUserType === props.$sectionType || props.$highlightedUserType === ''
      ? 1
      : 0.1};
  transition: opacity 0.7s ease;
`;


const GoghNowContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
width:100%;
// padding: 25px 20px;

justify-content: space-around;
background-color: #EEEEEE;
min-height: 50vh;
`

const GoghNowContainerODD = styled.div`

${mobileDisplayContentVertical};

display: flex;
width:100%;
// padding: 25px 20px;
justify-content: space-around;
background-color: #EEEEEE;
min-height: 25vh;
`

const GoghNowContent = styled.div`
display: flex;
// position: sticky;
// top: 0px;
// right: 0px;
flex: 0 0 calc(50% - 25px);
flex-direction: column;
justify-content: center;
align-items: start;
padding: 36px 36px;
gap: 30px;
background-color: ${props => props.$backgroundColor ? `${props.$backgroundColor}` : '#EEEEEE'};  // Corrected the string interpolation
min-height: 25vh;

@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowContentInnerOutlineDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
// padding: 36px 36px;
gap: 30px;
// background-color: ${props => props.$backgroundColor ? `${props.$backgroundColor}` : '#EEEEEE'};  // Corrected the string interpolation
background-color: transparent;
height:auto;
width: auto;
border: solid 1px #00000060;
padding: 36px 36px;
min-height: 25vh;
@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowContentNonSticky = styled.div`
display: flex;
position: sticky;
top: 0px;
right: 0px;
flex: 0 0 calc(50% - 25px);
flex-direction: column;
justify-content: center;
align-items: start;
padding: 36px 36px;
gap: 30px;
background-color: ${props => props.$backgroundColor ? `${props.$backgroundColor}` : '#EEEEEE'};  // Corrected the string interpolation
min-height: 25vh;
@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowContentMobile = styled.div`
display: flex;
flex: 0 0 calc(50% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
padding: 36px 36px;
// gap: 20px;

@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowContentExpanded = styled.div`

${mobileSizeDisplayBlock};

display: flex;
flex: 0 0 calc(100% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px 0px;
gap: 0px;
@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 0px;
}
`

const GoghNowHeader = styled.h2`


padding: 0px 0px;
text-align: left;
width: 100%;
margin: 0px;
`

const GoghNowSubHeader = styled.h3`
color:black;
font-weight: 200;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
text-align: left;
width: 100%;

`

const GoghNowSubHeaderMobile = styled.h3`

${mobileSizeDisplayBlock};

color:black;
font-weight: 700;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
text-align: left;

`


const ProviderImageContainer = styled.div`
margin-bottom: 0px;

width: 50%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: flex;
margin:0px;
padding:0px;

@media (max-width: 769px) {
    flex-grow: 0;
    width: 50%;
}

img {
    display: block;
    width:100%;
}


`

const GridImageContainer = styled.div`
margin-bottom: 0px;
flex-grow:0;
flex-shrink:0;
width: 100%;
aspect-ratio: 1 / 1;
max-width:50dvw;
font-size: 0px;
display: flex;
  background-color: white;
cursor:pointer;

&:hover{
// box-shadow: 1px 1px 2px #00000060;
background-color:transparent;
cursor:pointer;
}

@media (max-width: 769px) {
    flex-grow: 0;
    width: 50%;
}

img {
    // display: block;
    // max-width:100%;
    // max-height:100%;
    // height:auto;
    // width:auto;

        &:hover {
    transition: transform 0.65s ease, padding 0.65s ease; /* Smooth animation for transform and padding */
    transform-origin: center center; /* Scale relative to the center */
        transform: scale(0.975); /* Shrink the image by 10% */
    }
}

`
const GridContentContainer = styled.div`
display: flex;
flex-direction:column;
flex-grow: 1;
justify-content: ${(props) => {
    if (props.$isLarge) return 'start';
    return 'center'; // Default to large if none match
}}; ;
width: auto;
height:auto;
// width: 100%;
// padding: 15px 0px;
align-items: ${(props) => {
    if (props.$isLarge) return 'center';
    return 'start'; // Default to large if none match
}}; 
text-align: ${(props) => {
    if (props.$isLarge) return 'center';
    return 'left'; // Default to large if none match
}}; 
gap: ${(props) => {
    if (props.$isLarge) return '25px';
    return '15px'; // Default to large if none match
}}; 
margin: 0px 1rem 2rem;
`

const FlexGroupContainer = styled.div`
display:flex;
flex-direction: column;
flex-grow:${(props) => {
    if (props.$isLarge) return '1';
    return '0'; // Default to large if none match
}}; 

align-items: 'start';
justify-content: start;
`
// const GoghNowHeaderMobile = styled.h2`

// ${mobileSizeDisplayBlock};
// display: none;
// color:black;
// font-weight: 700;
// // font-size: 45px;
// line-height: 90%;
// margin-bottom: 0px;
// margin-top: 0px;
// text-align: left;
// `
const GoghNowDescription = styled.p`
color: black;
// font-size: 18px;
margin: 0px;
// text-align: left;
// font-size: 2.0em;
font-weight: 200;
`

const GoghNowHeaderType = styled.h4`
color: ${props => props.$textColor ? `${props.$textColor}` : '#AAAAAA'};  // Corrected the string interpolation
// margin: 0px;
padding: 0px;
`

const GoghNowImageContainer = styled.div`

// margin-top: 50px;
margin-bottom: 0px;

width: 75%;
max-height: 100%;
font-size: 0px;
display: display;
justify-content: center;

img {
    display: block;
    width:100%;
}
`

const QuarterImageContainer = styled.div `

// margin-top: 50px;
margin-bottom: 0px;

display:flex;
height: 100%;
width: 100%;
flex-wrap: wrap;
gap: 0x;
flex: 0 0 calc(25% - 0px);

img {
    width: 50%;
    height:50%;
    position: relative;
}
`



//NEW ELEMENTS
const ActionButton = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 50px;
border-radius: 25px;
border: 1px solid #CCC;
background-color: #FBAE62;
color: white;
cursor: pointer;
// margin-top: 25px;
height: 50px;

&:hover{
    background-color: #FBAE6260;
}
`


const ActionContainer = styled.div `
display:flex;
width: 100%;
gap: 25px;
align-items: end;
justify-content: center;
flex-wrap: wrap;
`


//SignUp Container
//SECTION 1

const CustomSignUpComponent = styled(SignUpComponent)`
width: 650px;
background-color: transparent;
padding: 0px;
`
const UpdatesSection = styled.div`
// overflow: hidden;
display: flex;
// position: relative;
flex-direction: row;
text-align: center;
// min-height: 100vh;
width: 100%;
justify-content: center;
align-items: center;
background-color: #3F3F3F90;
padding: 50px 36px;
`

const UpdatesContainer = styled.div`
display: flex;
// max-width: 1184px;
padding: 50px 0px;
justify-content: center;
width: 100%;
align-items: center;
text-align: center;
h2, p {
text-align: center;
}
`

const Updates = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
// background-color: #FBAE6220;
padding-bottom: 36px;
max-width:650px;

button {
    // font-weight: bold;
    color: black;
    background-color: #FBAE6260;
    width: auto !important;
    height: 50px !important;
    padding: 0px 15px;
    border-radius: 25px;
    margin: 10px;
    // margin-left: 25px;
    // margin-right: 25px;

    font-size: 18px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover{
        background-color: #FBAE62;
        color: white;
    }
}
`


//General Elements
const ImageContainer = styled.img`
position: relative;
width: 100%;
height: auto;
`;





//RANDOM COMPONENTS
const OfferingLink = styled.div`
  display: flex;
  flex-shrink: 1;
  color: black;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
//   background-color: #FBAE6290;
  padding: 15px 0px;
  border-radius: 10px;
  width: auto;
  text-decoration: none;  /* Remove default underline */
  
  position: relative;  /* For positioning the underline */
  
 &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: black;
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 0;  /* Shrinks the underline from right to left */
    right: 0;  /* Positions the shrink effect from right */
  }


  /* Reappear underline animation after hover ends */
  &:not(:hover)::after {
    width: 100%;
  }

`;
















export default Home;






//https://www.youtube.com/watch?v=UUhoCb5nagY