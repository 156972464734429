import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import { useAuthContext } from 'context/AppContext/AuthContext';
import { useAuth } from 'third_party/redux/selectors';
//UPDATED ITEMS
import { useUserActions } from 'third_party/redux/user/userAction';
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { useSearch} from 'third_party/redux/selectors';
import SearchFunctions from 'utility/searchFunctions';

import GoogleMapsFunctions from 'utility/googleMapsFunctions';
// import { useGuestLoginWithPlace } from 'third_party/redux/convenientFunctions';
// import {getAddressFromAddressOrCoordinates} from "components/Inputs/GoogleMapsAddressInput"


const HomeURLContext = createContext();

export const useHomeURLContext = () => useContext(HomeURLContext) 



export const HomeURLProvider = ({ children }) => {

  const urlLocation = useLocation();
  const navigate = useNavigate();

  //NEW
  const {USER_UPDATE} = useUserActions()
  const {SEARCH_UPDATE} = useSearchActions()
  const searchCriteria = useSearch().current //THIS SHOULD BE REFERRED TO CURRENT
  // const loginAsGuestWithPlace = useGuestLoginWithPlace()
  //OLD
  // const { isLoggedIn, loggedInWithAddress } = useAuthContext();
  const {isLoggedIn} = useAuth()
  

  //is this initially loading the app
  const [isInitialLoad, setIsInitialLoad] = useState(true);








  // Parse URL on initial load
  //=========================
  useEffect(() => {

      //function to parse URL
      const initializeSearchCriteriaFromURL = async () => {
      const params = new URLSearchParams(urlLocation.search);
      const address = params.get('address');
      const lat = params.get('lat');
      const lng = params.get('lng');
      const date = params.get('date') ? new Date(params.get('date')) : null;
      const duration = params.get('duration');
  
      let place = searchCriteria.location;
  
      try {
        if (address || (lat && lng)) {
          const returnedPlace = await GoogleMapsFunctions.getPlaceFromAddressOrCoordinates({
            address,
            lat,
            lng,
          });
          if (returnedPlace) {
            place = returnedPlace;
            // USER_UPDATE({ lat: place.lat, lng: place.lng });
            // loginAsGuestWithPlace(place);
          }
        }
        const updatedSearch = SearchFunctions.createDictionaryFromSearchCriteria({ place, date, duration });
        SEARCH_UPDATE(updatedSearch);
      } catch (error) {
        console.error('Error fetching place:', error);
      }
    };
    
    //calling the function and then setIsInitialLoad to False
    if (isInitialLoad) {
      initializeSearchCriteriaFromURL().then(() => {
        setIsInitialLoad(false);
      });
    }
  }, [isInitialLoad, urlLocation.search]);
  


  // Sync state to URL
  //=========================
  useEffect(() => {
    if (!isInitialLoad) {
      const params = searchParamsBasedOnDictionary(searchCriteria);
      navigate({ search: params }, { replace: true });
    }
  }, [searchCriteria, isInitialLoad]);


  //used to update link
  const searchParamsBasedOnDictionary = (paramsDict) => {
    const newParams = new URLSearchParams();
    if (paramsDict.location) {
      const address = SearchFunctions.getAddressFromPlace(paramsDict.location);
      if (address) newParams.set('address', JSON.stringify(address));
    }
    if (paramsDict.date) newParams.set('date', paramsDict.date.toISOString());
    if (paramsDict.duration) newParams.set('duration', paramsDict.duration.toString());
    return newParams.toString();
  };
// Read the URL and update searchCriteria
//==================================================

// useEffect(() => {
//   const fetchPlace = async () => {
//       let place = searchCriteria.location;
//       let date = SearchFunctions.getISOStringFromDate(searchCriteria.date);
//       let duration = searchCriteria.duration;

//       // potential address joints
//       const params = new URLSearchParams(urlLocation.search);
      
//       // Check if date or duration exists
//       date = SearchFunctions.getISOStringFromDate(params.get('date')) || date;
//       duration = params.get('duration') || duration;
      
//       // Getting the location information
//       const address = params.get('address');
//       const lat = params.get('lat');
//       const lng = params.get('lng');

//       console.group('Address:', address);
//       console.log('Lat:', lat);
//       console.log('Lng:', lng);
//       console.log('Duration:', duration);
//       console.log('Date:', date);
//       console.groupEnd();

//       if (address || (lat && lng)) {
//           console.log("THERE IS ADDRESS OR LAT AND LNG");

//           try {
//               console.log("WE IN FETCHPLACE");
//               const returnedPlace = await GoogleMapsFunctions.getPlaceFromAddressOrCoordinates({ address, lat, lng }); // Wait for the promise to resolve
//               console.log("RETURNED PLACE IS:", returnedPlace);
              
//               // Update user with location if it's found
//               if (returnedPlace) {
//                   place = returnedPlace;
//                   const userUpdate = { lat: place.lat, lng: place.lng };
//                   USER_UPDATE(userUpdate);
//                   loginAsGuestWithPlace(place);
//               }
//           } catch (error) {
//               console.log('Error fetching place:', error);
//           }
//       }

//       // After fetching the place, update search criteria
//       const updatedSearch = SearchFunctions.createDictionaryFromSearchCriteria({ place, date, duration });
//       console.log("updatedSearch with:", updatedSearch);
//       SEARCH_UPDATE(updatedSearch);
//   };

//   if (isInitialLoad) {
//       console.group("IS INITIAL LOAD");
//       fetchPlace().then(() => {
//           // Set initial load to false only after fetchPlace is done
//           console.log("setIsInitialLoad is FALSE");
//           setIsInitialLoad(false);
//           console.groupEnd();
//       });
//   }

// }, []); // Add dependencies as necessary




//         //=============================
//         //==============================
//         // UPDATE URL from search
//         useEffect(() => {

//             console.group("INITIAL LOAD IT: ",isInitialLoad)
//             console.log("useEffect: update URL")
//             if(!isInitialLoad){
//                     console.log("WE SHOULD BE HERE")
//                     console.log("searchCriteria: ",searchCriteria)
//                     // const existingParams = new URLSearchParams(urlLocation.search); // Extract existing parameters
//                     const searchParams = searchParamsBasedOnDictionary(searchCriteria)
//                     // const searchParams = newParams.toString(); // Convert to string
//                     console.log("PARAMS GETTING UPDATED WITH: ",searchParams)
//                     navigate({ search: searchParams }, { replace: true }); // Update URL with combined parameters

//             }
//             console.groupEnd("groupEND")
//     }, [searchCriteria,isInitialLoad]);

//     const searchParamsBasedOnDictionary = (paramsDict) =>{
//       const newParams = new URLSearchParams();
//         if(paramsDict.location){
//           if (paramsDict.location && Object.keys(paramsDict.location).length > 0) {
//             newParams.set('address', JSON.stringify(SearchFunctions.getAddressFromPlace(paramsDict.location)));
//           }
//        }
//       //  else if(searchParams.lat && searchParams.lng){
//       //     newParams.set('lat', JSON.stringify(searchParams.lat));
//       //     newParams.set('lng', JSON.stringify(searchParams.lng));
//       //  }
//         if (paramsDict.date) {
//           newParams.set('date', paramsDict.date.toISOString());
//         }
//         if (paramsDict.duration) {
//           newParams.set('duration', paramsDict.duration.toString());
//         }
//         console.log("NEWPARAMS: ",newParams)
//         return newParams.toString()
//     }
    
      //================================
      //=================================

        return (
            <HomeURLContext.Provider value={{  }}>
            {children}
            </HomeURLContext.Provider>
        );
    };
