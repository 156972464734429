
import { get, post, put } from 'api/apiRequests'; // Assuming your `apiCalls.js` file is in the same directory
import ReactCookie from 'third_party/cookies/ReactCookies';
import apiUtils from 'api/apiUtils';
import { useStateManagementFunctions } from 'third_party/redux/stateManagementFunctions';

//TYPE ALIAS
type LoginResult = {
    token: string;
    refresh_token: string
};

type LogoutResult = {
    message: string
};

type RefreshTokenResult = {
    token: string;
};



export let loginURL = 'auth/login'
export let logoutURL = 'auth/logout'
export let refreshTokenURL = 'auth/token' //REMOVED THE GET IN HERE AND JUST SIMPLIFY 'auth/get/token'
export let validateTokenURL = 'auth/validate-token' //REMOVED THE GET IN HERE AND JUST SIMPLIFY 'auth/get/token'




class AuthServiceAPI {

    
static login = async(phone:string,email:string,password:string): Promise<LoginResult> => {

    try{
        const url = loginURL
        const params = {
            email:email,
            phone:phone,
            password:password,
        }
        const response = await post(url,params)
        
        // Save tokens in cookies
        apiUtils.saveTokens(response.data.token,response.data.refresh_token)

        //return data
        return response.data
    } catch(error){
        console.error('Error validating invite code:', error);
        const {logout} = useStateManagementFunctions()
        logout();
        throw error; // Re-throw the error for handling in the calling component
    }
}

static logout = async(token:string,refresh_token:string): Promise<LogoutResult> => {

    try{
        const url = loginURL
        const params = {
            token:token,
            refresh_token:refresh_token,
        }
        const response = await post(url,params)

        //delete tokens
        apiUtils.deleteTokens()

        //return message
        return response.data
    } catch(error){
        console.error('Error validating invite code:', error);
        throw error; // Re-throw the error for handling in the calling component
    }
}


// refresh_token = async(token:string,refresh_token:string): Promise<RefreshTokenResult> => {
static refresh_token = async(refresh_token:string): Promise<RefreshTokenResult> => {

    try{
        const url = refreshTokenURL
        const params = {
            // token:token,
            refresh_token:refresh_token,
        }
        const response = await post(url,params)
        
        //save token in cookie :) 
        // apiUtils.saveToken(response.token)
        const {login} = useStateManagementFunctions()
        login(response.token,refresh_token,response.user_type)

        //return joint
        return response.data
    } catch(error){
        console.error('Error validating invite code:', error);
        throw error; // Re-throw the error for handling in the calling component
    }
}



// validate_token = async(token:string,refresh_token:string): Promise<RefreshTokenResult> => {

//     try{
//         const url = validateTokenURL
//         const params = {
//             token:token,
//             refresh_token:refresh_token,
//         }
//         const response = await post(url,params)
        
//         //save token in cookie :) 
//         apiUtils.saveToken(response.token)

//         //return joint
//         return response.data
//     } catch(error){
//         console.error('Error validating invite code:', error);
//         throw error; // Re-throw the error for handling in the calling component
//     }
// }



}

export default AuthServiceAPI
