import { toJSON } from 'api/apiUtils';//fromJSON
// import UserApiRoutes from '../ApiRoutes';
// import { UserRequestRoute } from './RequestServiceRoutes';
import SearchFunctions from 'utility/searchFunctions';
// import { ObjectUserRequestClass } from 'model/RequestObjects/objectUserRequest';

//CLASS
import { ObjectUserRequestClass } from 'model/RequestObjects/objectUserRequest'; //contains objectRequest item
import { ObjectRequestItemClass } from 'model/RequestObjects/objectRequestItem'; //for each item

import apiUtils from 'api/apiUtils';


export const UserRequestRoute = '/php/UserBookForm.php'

class RequestServiceAPI {

  static async createRequest(searchCriteria, cartItems, user, requestDetails){
      const end = new Date(searchCriteria?.date)
      end.setMinutes(end.getMinutes()+ searchCriteria?.duration)

      const item = {
          'name': user?.name || '',
          'email': user?.email || '',
          'phone': user?.phone || '',
          'start': searchCriteria?.date,
          'end': SearchFunctions.createEndDate(searchCriteria?.date, searchCriteria?.duration),
          'duration': SearchFunctions.displayDuration(searchCriteria?.duration),
          'address': SearchFunctions.getAddressFromPlace(searchCriteria?.location),
          'locationDetails': searchCriteria?.location,
          'services': cartItems || [],
          'budget': requestDetails?.budget || '',
          'details': requestDetails?.details || '',
          'promo_code': requestDetails?.promo_code || '',
          'time':SearchFunctions.displayDateAndDuration(searchCriteria?.date,searchCriteria?.duration),
          'created': new Date(),
          }
      console.log('SUBMIT ITEM: ', item)
      
      const JSONdata = apiUtils.toJSON(item)
      console.log('JSON: ', JSONdata)
      
      try {
          const response = await fetch(UserRequestRoute, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSONdata,
            });

          // Check response headers and status
          console.log('Response Headers: ', response.headers);
          console.log('Content-Type: ', response.headers.get('Content-Type'));
          console.log('Response Status: ', response.status);
          console.log('response.ok: ', response.ok);

            //FAILED
            if(!response.ok){
              const errorMessage = await response.text();
              throw new Error(errorMessage || 'Request failed');
            }

            //SUCCESS
            console.log("Success")

            // Log the full response text
            const responseText = await response.text();
            console.log('Full Response Text:', responseText);


          // Check if the response is actually JSON
            try {
                const data = JSON.parse(responseText);
                console.log('Data from PHP:', data);
                return { data, error: null };
            } catch (parseError) {
                console.error('Error parsing JSON:', parseError);
                throw new Error('Invalid JSON response');
            }            
      }
      catch (error) {
          //CAUGHT ERROR
          console.log("THERE IS AN ERROR: ", error);
          return {data: null, error};
      }
  }

}

export default RequestServiceAPI