
import { ObjectUserStaging } from 'model/objectUserStaging';


//API LINKS
class ConstantsAPI {

    getIconFilters = async(): Promise<IconFilters> => {
        try {
        //   const url = ApiRoute.signUpGetAccessCode
        //   const params = { phone_number: phoneNumber }
        //   const response = await get(url, params);
        //   return response.data //.access_code; // Assuming the API returns a JSON with an 'access_code' string
            return getIconFiltersData()
        } catch (error) {
          console.error('Error getting access code:', error);
          throw error;
        }
      }

      getEventServices = async(): Promise<EventServices> => {
        try {
        //   const url = ApiRoute.signUpGetAccessCode
        //   const params = { phone_number: phoneNumber }
        //   const response = await get(url, params);
        //   return response.data //.access_code; // Assuming the API returns a JSON with an 'access_code' string
            // return getAvailableGoghNowServices() //Initial CODE NEEDED IS JUST THIS LINE
        
        //NEW SHIT BRO
        // Fetch the available company services
          const services =  getAvailableGoghNowServices();
          
          // Define the category-to-image mapping (you can move this to an external variable or a constant)
          const categoryIcons = getIconFiltersData()

    // Enrich the services with category icons
    const enrichedServices = services.map(service => {
      const categoryIcon = categoryIcons.find(icon => icon.value === service.category[0]);
      return {
        ...service,
        categoryIcon: categoryIcon ? categoryIcon.image : '',  // Add the image if found
      };
    });
    return enrichedServices
        } catch (error) {
          console.error('Error getting access code:', error);
          throw error;
        }
      }


}

export default ConstantsAPI;




//XXXXXXXXXXXXXXXXXXXX
//XXXXXXXXXXXXXXXXXXXX
//DEFINED ITEMS
//XXXXXXXXXXXXXXXXXXXX
//XXXXXXXXXXXXXXXXXXXX


  //==============
  //ICON FILTERS
  //===============

  // Define the type based on the structure of the filters in IconFilters
type IconFilter = {
    text: string;
    image: string;
    value: string;
};

// Updated AccessCodeResult to be an array of IconFilter objects
type IconFilters = IconFilter[];

const getIconFiltersData = (): IconFilters=> {
    return [
        { text: 'All', image: 'images/Home/icons/logo-blkwht.png' ,value:'all'},
        { text: 'Capture', image: 'images/Home/icons/capture.png' ,value:'capture'},
        { text: 'Decor', image: 'images/Home/icons/decor.png',value:'decor'},
        { text: 'Food/Snacks', image: 'images/Home/icons/food.png',value:'eats' },
        { text: 'Music', image: 'images/Home/icons/note.png',value:'music' },
    ];
  };

  //==============
  //EVENT SERVICES
  //===============
  // Define the type for the image object
type EventServiceImage = {
    alt: string;
    image: string;
  };
  
  // Define the main type for the event service item
  type EventService = {
    id: string;
    title: string;
    description: string;
    images: EventServiceImage[];
    category: string[];
    categoryIcon?: string;
    picture: string;
    name:string;
  };

  type EventServices = EventService[]
  const getAvailableGoghNowServices = (): EventServices => {
    return [
        { id : '1',
          title: 'DJ',
          description: "Professional DJ to keep your event lively with a mix of music",
          images:[
            {alt:'DJ',image: 'https://images.unsplash.com/photo-1461784180009-21121b2f204c?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          ],
          category : ['music'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '2',
          title: 'Acoustic',
          description: " Live acoustic music to create a soothing and intimate atmosphere.",
          images: [
            {alt:'Accoustic', image: 'https://images.unsplash.com/photo-1471478331149-c72f17e33c73?q=80&w=1769&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          ],
          category : ['music'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '3',
          title: 'Photography',
          description: "Capture your event with professional photography services",
          images: [
            {alt:'Photography', image: 'https://images.unsplash.com/photo-1516035069371-29a1b244cc32?q=80&w=1638&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          ],
          category : ['capture'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '4',
          title: 'Band',
          description: "Live band performances to energize your event with great music",
          images: [
            {alt:'Photography', image: 'https://media.istockphoto.com/id/74075509/photo/portrait-of-a-rock-band.jpg?s=612x612&w=0&k=20&c=XX9NvoFTA1VCLov9Dj97fhPjxXZaywY40XagdOJmjHE='},
          ],
          category : ['music'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '5',
          title: 'Videography',
          description: "Professional video recording to document your event in high quality",
          images: [
            {alt:'DJ', image: 'https://images.unsplash.com/photo-1596483941348-cb6d15e0fd60?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          ],
          category : ['capture'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '6',
          title: 'Playlist',
          description: "Curated playlist for your occasion",
          images: [
            {alt:'Playlist', image: 'https://i.pinimg.com/736x/8a/52/de/8a52deab0bb127abbebeeeb576ee78a9.jpg'},
          ],
          category : ['music'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '7',
          title: 'Back Drop',
          description: "Stylish backdrops to enhance the visual appeal of your event",
          images: [
            {alt:'backdrop', image: 'https://www.ubackdrop.com/cdn/shop/files/GreenTropicalJungleFabricArtificialFlowerWallBackdropPartyDecor_89a5510f-058b-48dc-9ed4-dd2f245036a1.jpg?v=1711940374'},
            {alt:'backdrop flowers', image: 'https://www.valarflowers.com/cdn/shop/files/B28A9665.jpg?v=1696214587&width=500'}
          ],
          category : ['decor'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '8',
          title: 'Floral Decor',
          description: "Beautiful floral arrangements to add elegance to your occasion",
          images: [
            {alt:'floral decor', image: 'https://images.unsplash.com/photo-1615654673360-29898e4f857f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGZsb3JhbCUyMGFycmFuZ2VtZW50fGVufDB8fDB8fHww'},
            {alt:'floral decor', image: 'https://images.unsplash.com/photo-1561593367-66c79c2294e6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fGZsb3JhbCUyMGFycmFuZ2VtZW50fGVufDB8fDB8fHww'}
          ],
          category : ['decor'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '9',
          title: 'Balloon Decor',
          description: "Creative balloon decorations to make your event festive",
          images: [
            {alt:'balloon decor', image: 'https://images.unsplash.com/photo-1530103862676-de8c9debad1d?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
            {alt:'balloon decor', image: 'https://i5.walmartimages.com/seo/Navy-Blue-Sliver-Balloons-Garland-Kit-85-Metallic-Party-Arch-Latex-Black-Agate-Marble-Balloon-Decoration-For-Graduation-Home-Engagement-Birthdays-Dec_38810db9-6129-4590-9b2b-457597738a21.114259a6bbc09a06ed1620fa1fdad74c.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF'}
          ],
          category : ['decor'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '10',
          title: 'Charcuterie',
          description: "Delicious charcuterie boards featuring a variety of meats, cheeses, and more",
          images: [
            {alt:'Charcuterie', image: 'https://images.unsplash.com/photo-1541529086526-db283c563270?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
            {alt:'Charcuterie', image: 'https://images.unsplash.com/photo-1579916087028-2cedd4dceceb?q=80&w=1635&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
          ],
          category : ['eats'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '11',
          title: 'Art Display',
          description: "Captivating art displays from local artists to add a unique visual flair to your event.",
          images: [
            {alt:'Art Display', image: 'https://images.unsplash.com/photo-1618555108331-9567e652f9eb?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
            {alt:'Art Display', image: 'https://images.unsplash.com/photo-1514195037031-83d60ed3b448?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
            {alt:'Art Display', image: 'https://images.unsplash.com/photo-1565876427310-0695a4ff03b7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fHw%3D'}
          ],
          category : ['decor'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '12',
          title: 'Lighting',
          description: "Professional lighting setup to create the perfect ambiance",
          images: [
            {alt:'spotlight', image: 'https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
            {alt:'string lights', image: 'https://images.unsplash.com/photo-1507494924047-60b8ee826ca9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bGlnaHRpbmd8ZW58MHx8MHx8fDA%3D'},
            {alt:'spotlight', image:'https://images.unsplash.com/photo-1559154132-6d27bd0c65b0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGxpZ2h0aW5nfGVufDB8fDB8fHww'}
          ],
          category : ['decor'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
        { id : '13',
          title: 'Food Truck',
          description: "Mobile food service offering a variety of delicious meals and snacks for your event",
          images: [
            {alt:'foodtruck', image: 'https://www.partstown.com/about-us/wp-content/uploads/2023/01/Food-Truck.jpg'}
          ],
          category : ['eats'],
          picture: '/images/cta-logo-one-wht.svg',
          name:'goghnow: locally sourced'
        },
    ];
  }