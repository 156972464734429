import {AUTH_LOGIN_GUEST, AUTH_LOGIN_USER, AUTH_LOGOUT} from "third_party/redux/actionTypes";

//OBJECT
const initialState = {
    isLoggedIn: false,
    // token: false,
    // user_id: null,
    user_type: null,
}

//#################################
//#############REDUCERS############
//#################################

const authReducer = (state = initialState, action: any) => {

    switch(action.type){
        // case AUTH_LOGIN_GUEST:
        //     return{
        //         ...state,
        //         isLoggedIn: true,
        //         user_type:action.payload ?? "guest"
        //     };
        


        case AUTH_LOGIN_USER:
            return{
                ...state,
                isLoggedIn: true,
                user_type:action.payload.user_type ?? "user",
                // token: action.payload.token,
                // user_id: action.payload.user_id
            };

        case AUTH_LOGOUT:
            return initialState

        default:
            return state
    }

}
export default authReducer