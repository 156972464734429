import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';


const SubmitButton = ({text, onClick, ...props}) => {
//i removed className
return (
    <Button onClick={onClick} {...props} >{text}</Button>
)


}
export default SubmitButton;

const Button = styled.button`

    padding: 10px 20px;
    background-color: #FBAE62;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    text-align: center;
    height: 60px;

    &:hover {
        background-color: #FBAE6260;
   }
`