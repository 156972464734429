import React from 'react';
import styled from 'styled-components';

const FAQItem = ({ question, answer, isOpen, onClick }) => (
  <>
    <FAQItemContainer onClick={onClick}>
        <FAQItemHeaderContainer>
            <Question>{question}</Question>
            <Icon>{isOpen ? '-' : '+'}</Icon>
        </FAQItemHeaderContainer>
        <Answer $isVisible={isOpen}>{answer}</Answer>
    </FAQItemContainer>

  </>
  
);

const FAQItemContainer = styled.div`
display: flex;
flex-direction: column;
padding: 25px;
cursor: pointer;
background-color: #00000020;
`
const FAQItemHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Question = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 1.2em;
  color: #000000;
`;

const Answer = styled.p`
  margin: 0;
  font-size:1.25rem;
  padding: 10px 0;
  display: ${(props) => (props.$isVisible ? 'block' : 'none')};
  color: #00000080;
`;

const Icon = styled.span`
  font-size: 2.0em;
  margin-left: 10px;
`;

export default FAQItem;
