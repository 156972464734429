//Standard Stuff
import React, {useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

//styled components
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//cart stuff
import { useCart } from "third_party/redux/selectors"
import { useCartActions } from "third_party/redux/cart/cartActions";

//utility function
import SearchFunctions from "utility/searchFunctions"

//
// import { useAppNavBarContext } from "context/AppNavBarContext"
//Cart Menu
import CartMenu from "components/Cart/CartMenu"
import {useSearch } from "third_party/redux/selectors";


const CartButton = ({onCheckout, ...props}) => {

    const navigate = useNavigate();


    const searchCriteria = useSearch()?.current || {}; // Safe fallback to an empty object
    const cartItems = useCart()


    const {CART_DELETE} = useCartActions()
    const countCartItems = SearchFunctions.countCartItems(cartItems); // Safely check the length or default to 0
    
    //Cart Functions
    const [isCartMenuOpen, setIsCartMenuOpen] = useState(false);
    const toggleCartMenu = () => {
        setIsCartMenuOpen(prev => (prev === false ? true : false));
    };
    const handleCartMenuClose = () =>{
        setIsCartMenuOpen(false)
      }
      const handleDeleteItemFromCart = (item) => {
        // dispatch(deleteCartItem(item));
        CART_DELETE(item)
    };

    const cartButtonPressed = () =>{
        console.log("HERE")
        toggleCartMenu()
    }
    // const goToCheckout = () => {

    //     navigate('/book', {state: {searchCriteria, cartItems}});
    //   }
    // isCartMenuOpen,
    // setIsCartMenuOpen,
    // toggleCartMenu,

    const handleCheckout = () => {
      navigate('/book', {state: {searchCriteria, cartItems}});
    }

return (
    <>
    <Container {...props}>
        <Button onClick={cartButtonPressed}>
            <IconContainer>
                <img src="/images/cart.svg" alt="Cart" />
            </IconContainer>
        </Button>
        <Indicator>{countCartItems}</Indicator>
    </Container>
    <CartMenu
        isOpen={isCartMenuOpen}
        onClose={handleCartMenuClose}
        cartItems={cartItems}
        removeFromCart={handleDeleteItemFromCart}
        handleCheckout={handleCheckout}
        canToggle={true}
    />
    </>
)


}
export default CartButton;


const Container = styled.div`
display: block;
position: relative;
height: auto;
width: auto;
`
const Button = styled.div`

    display: flex;
    padding: 2.5px 2.5px;
    background-color: #FBAE6260;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    &:hover {
        background-color: #FBAE62;
   }
`

const IconContainer = styled.div`
width: 40px;
height: 40px;
align-items: center;
justify-content: center;
display: flex;

img {
    display: flex;
    width:65%;
    height: 65%;
    color: black;
}
`

const Indicator = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: red;
color: white;
position: absolute;
top: 0px;
right: calc(0px - 10px);
aspect-ratio: 1/1;
height: 20px;
width: 20px;
border-radius: 75%;
padding: 2px 4px;
`