
import styled from 'styled-components'; // Assuming you're using styled-components
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import React from 'react';


import { css } from '@emotion/css';

const EventServiceItemSkeleton = ({cards}) => {
  return (
    // Array(cards).fill(0).map(item => 
    <>
    <style>
    {`
    .image-style {
        display: flex;
        width: 100%;
        aspect-ratio: 1 / 1; /* Sets aspect ratio to 1:1 (consider browser support) */
        margin-bottom: 5px;
    }
    .title-style{
        display: flex;
        width: 100%;
        padding-top:5px;
    }
    .description-style{
        display: flex;
        flex-wrap: wrap;
        min-width: 100%;
        margin-top: 0px;
        margin-bottom:0px;
        padding-bottom: 0px;
        padding-top: 5px;
    }
    .description-style > span {
        margin-bottom: 2.5px;
        }
    `}
    </style>
    {Array(cards)
        .fill(0)
        .map((_, index) => (
    <Container key={index}>
      <EventServiceImageContainer>
        <Skeleton containerClassName="image-style" />
      </EventServiceImageContainer>
      <TextContainer>
        <Skeleton count={1} containerClassName="title-style" />
        <Skeleton count={2} containerClassName="description-style" />
      </TextContainer>
    </Container>
  ))};
  </>
  );
};

export default EventServiceItemSkeleton;

const TEST = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100%;
  overflow-x: hidden;
 `

const EventServiceImageContainer =  styled.div`
    display: flex;
    height: 100%; /* Fills available space within the grid item */
    position: relative;
    `
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
//   height: 5em;
//   justify-content: start;
//   align-items: start;
`;

const TitleSkeleton = styled(Skeleton)`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: black;
  margin: 0px;
`;

const DescriptionSkeleton = styled(Skeleton)`
  font-size: 12px;
  font-weight: 100;
  color: #00000060;
  margin: 0px;
  text-align: left;
  height: 1.2em; /* Adjust for two lines and spacing */
  margin-bottom: 0.2em; /* Add spacing between lines */
`;

