import cookie from 'react-cookies';

class ReactCookie {
    static TOKEN_KEY = null;
    static REFRESH_TOKEN_KEY = null;

    // Save token to cookies
    static saveToken(token, options = { path: '/' }) {
        cookie.save(this.TOKEN_KEY, token, options);
    }

    // Save refresh token to cookies
    static saveRefreshToken(refreshToken, options = { path: '/' }) {
        cookie.save(this.REFRESH_TOKEN_KEY, refreshToken, options);
    }

    // Load token from cookies
    static getToken() {
        return cookie.load(this.TOKEN_KEY);
    }

    // Load refresh token from cookies
    static getRefreshToken() {
        return cookie.load(this.REFRESH_TOKEN_KEY);
    }

    // Clear token from cookies
    static deleteToken(options = { path: '/' }) {
        cookie.remove(this.TOKEN_KEY, options);
    }

    // Clear refresh token from cookies
    static deleteRefreshToken(options = { path: '/' }) {
        cookie.remove(this.REFRESH_TOKEN_KEY, options);
    }

    // General method to save both token and refresh token
    static saveTokens({ token, refreshToken }, options = { path: '/' }) {
        if (token) this.saveToken(token, options);
        if (refreshToken) this.saveRefreshToken(refreshToken, options);
    }

    // General method to load both token and refresh token
    static getTokens() {
        return {
            token: this.loadToken(),
            refreshToken: this.loadRefreshToken(),
        };
    }

    // General method to clear both token and refresh token
    static deleteTokens(options = { path: '/' }) {
        this.deleteToken(options);
        this.deleteRefreshToken(options);
    }
}

export default ReactCookie;