import React from "react";
import { useNavigate } from "react-router-dom";


//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';


const BreadcrumbNavigation = ({currentPage}) => {
  const navigate = useNavigate();

  const handleAccountSettingsClick = () => {
    navigate("/account-settings");
  };

  return (
    <Box>
      <Link
        onClick={handleAccountSettingsClick}
      >
        account-settings
      </Link>
      <Text>/</Text>
      <TextSelected>{currentPage}</TextSelected>
    </Box>
  );
};



export default BreadcrumbNavigation;

const Box = styled.div`
display: flex;
gap: 8px;
`
const Text = styled.p`
color: black;
`
const TextSelected = styled.p`
color: black;
// font-weight: bold;
`
const Link = styled.p`
    magin: 0px 8px;
    cursor:pointer;
    // font-weight: bold;
    color: #00000060;
    border: none;
        background-color: transparent;
    &:hover{
    color:black;
    border-bottom: 1px solid black;
    }
`