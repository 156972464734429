import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';

import { Helmet } from 'react-helmet-async';



import NavBarLogout from 'pages/home/header/NavBarLogout';
import NavBarLogoutGeneral from 'components/navbar/NavBarLogoutGeneral';


import {PageContentContainer} from "components/Page/Elements"

import { buttonSubmitStyle } from 'components/Buttons/ButtonStyles';
import { useScreenContext } from 'context/AppContext/ScreenContext';



const BizDevRep = () => {

    const {isMobile, isMedium, isLarge} = useScreenContext()


    const jobtitle = 'Business Development Rep I - GoghNow For Business'
    const jobsubtitle = 'Business Development'
    const joblocation = 'Remote'
    const jobsubtitledescription = 'Part Time/Project-Based'

    const jobDescription = `As a Sales/Leads Specialist for GoghNow for Business, you’ll have a unique opportunity to drive growth and success for an innovative tech platform that provides on-demand event services for businesses and organizations. You will help businesses streamline their event planning by accessing a wide range of services directly on our platform, as well as foster partnerships with businesses that rent out event spaces, allowing their clients to use our services easily.

    Working closely with the CEO and COO, you will play a key role in generating leads, forming strategic partnerships, and contributing to the GoghNow’s revenue growth. Your role will involve cultivating relationships with business leaders, offering strategic insights, and driving the adoption of GoghNow's platform across various sectors. This position is perfect for a results-oriented individual who is passionate about working in a fast-paced startup environment, where you can make a significant impact.`;
    const jobDescriptionSplit = jobDescription.split('\n\n');

    const WhatYoullDo = [
        "Develop and execute a strategic business development plan to increase GoghNow's market presence in the business event services sector.",
        "Generate leads and foster partnerships with businesses looking to fulfill their event needs directly through our platform.",
        "Collaborate with businesses that rent event spaces, helping their clients seamlessly access our services to enhance their event experiences.",
        "Work with cross-functional teams (Sales, Marketing, Finance, Operations) to support contract negotiations, build strategic deals, and drive them to closure.",
        "Manage and grow long-term partnerships, ensuring mutual value for both GoghNow and its partners.",
        "Analyze data to evaluate current partnerships, identify new opportunities, and provide insights to improve business outcomes.",
        "Provide strategic input to leadership, aligning business goals with overall GoghNow direction.",
        "Assist in the creation of scalable processes to support growth and ensure exceptional service delivery."
      ];

      const Requirements = [
        "3-5 years of experience in sales, business development, or partner management, with a preference for experience in B2B markets.",
        "A proven track record of closing and managing complex partnerships that drive strategic growth and revenue generation.",
        "Strong collaboration skills and the ability to work effectively with internal teams across different functions such as finance, operations, and marketing.",
        "Experience working directly with C-level executives, influencing decision-making processes, and driving business growth.",
        "Excellent communication and presentation skills, with the ability to build strong relationships with external partners and internal teams.",
        "Strong analytical skills with the ability to interpret data, generate insights, and leverage data-driven decisions.",
        "High adaptability to change in a fast-paced startup environment, with the ability to execute quickly and effectively."
      ];

      const ExperienceRequirements = [
        "Previous sales and account management experience",
        "Existing event experience (corporate and/or independently)",
        "EA/Office Manager experience"
      ];

      const Benefits = [
        "Be a part of a groundbreaking GoghNow that's transforming the way events are managed.",
        "Work in a dynamic and fast-paced environment where your ideas are valued.",
        "Collaborate with a talented and passionate team.",
        "Make a real impact on the GoghNow's growth and success.",
        "Enjoy competitive compensation."
      ];


      const handleClick = () => {
        window.location.href = `mailto:careers@goghnow.com?subject=GoghNow Applicant: Business Development Rep I`;
      };


    return (
        <>
            <Helmet>
                <title>GoghNow Careers | Explore GoghNow Careers</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="GoghNow Careers | Build The Future of Events" />
                <meta name="description" content="GoghNow is a one-stop-shop for event services" />
                <meta property="og:image" content="images/cta-logo-one.png" />
            </Helmet>
            <NavBarLogoutGeneral />
            <PageContentContainerCustom className="content">
                <MainPost>
                    <JobTitle>{jobtitle}</JobTitle>
                    <JobSubTitle>{jobsubtitle} in {joblocation}</JobSubTitle>
                    <JobSubTitleDescription>{jobsubtitledescription}</JobSubTitleDescription>
                    <ApplyButton onClick={handleClick} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Submit Resume Today</ApplyButton>
                    <ParagraphContainer>resumes can be submitted to <b>careers@goghnow.com</b></ParagraphContainer>
                    <Line />

                    <MiniHeader>About This Role</MiniHeader>
                        <ParagraphContainer>
                            {jobDescriptionSplit.map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </ParagraphContainer>

                    <MiniHeader>What You’ll Do:</MiniHeader>
                    <StyledList>
                        {WhatYoullDo.map((task, index) => (
                            <StyledListItem key={index}>{task}</StyledListItem>
                        ))}
                    </StyledList>

                    <MiniHeader>What You’ll Need:</MiniHeader>
                    <StyledList>
                        {Requirements.map((requirement, index) => (
                            <StyledListItem key={index}>{requirement}</StyledListItem>
                        ))}
                    </StyledList>

                    <MiniHeader>Nice To Have Skills</MiniHeader>
                    <StyledList>
                        {ExperienceRequirements.map((niceToHave, index) => (
                            <StyledListItem key={index}>{niceToHave}</StyledListItem>
                        ))}
                    </StyledList>

                    <MiniHeader>Why GoghNow for Business</MiniHeader>
                    <StyledList>
                        {Benefits.map((benefit, index) => (
                            <StyledListItem key={index}>{benefit}</StyledListItem>
                        ))}
                    </StyledList>
                    <Line />
                    <ApplyButton onClick={handleClick} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Submit Resume Today</ApplyButton>
                    <ParagraphContainer>resumes can be submitted to <b>careers@goghnow.com</b></ParagraphContainer>

                </MainPost>
            </PageContentContainerCustom>

        </>
    )
}

export default BizDevRep;

const PageContentContainerCustom = styled(PageContentContainer)`
background-color: white;
color: black;
padding: 48px 24px 36px;
align-items: center;
`
const MainPost = styled.div`
display: flex;
flex-direction: column;
max-width: 800px;
`

const JobTitle = styled.h1`
margin-bottom: 0px;
`
const JobSubTitle= styled.h3`
margin-top: 0px;
margin-bottom: 0px;
`
const JobSubTitleDescription = styled.h3`
margin-top: 0px;
`

const MiniHeader = styled.p`
  font-weight: bold;
`

const NormalText = styled.p`

`

const ParagraphContainer = styled.div`
  p {
    margin-bottom: 1em;
  }
`;



// Styled unordered list and list item
const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 20px; /* Adds padding to indent bullet points */
`;

const StyledListItem = styled.li`
  margin-bottom: 0.5em; /* Adds space between list items */
`;


const ApplyButton = styled.button`
${buttonSubmitStyle}
  width: ${props => {
    if (props.$isMobile) return '100%';
    if (props.$isMedium) return '75%';
    if (props.$isLarge) return '50%';
    return '50%'; // Default to large if none match
  }}; 
margin: 25px 0px 0px 0px;
`

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin-top: 10px; /* Adjust the spacing above the line */
`;