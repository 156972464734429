import React, { createContext, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { NAVBAR_HEIGHT } from "components/constants";
import MenuItem from "../SignUpMenu/MenuItem";
import { Z_INDEX_POPUP_MENUS } from 'components/constants';
import { useScreenContext } from 'context/AppContext/ScreenContext';
//CONTEXT
const GeneralSignUpMenuContext = createContext();

export const useGeneralSignUpMenuContext = () => useContext(GeneralSignUpMenuContext);

export const GeneralSignUpMenuProvider = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  // const toggleMenu = () => setMenuOpen(!isMenuOpen);
  const toggleMenu = (menuType) => {
    if (isMenuOpen) {
      if (currentMenuType === menuType) {
        setMenuOpen(false); // Close the menu if the same menu is clicked
      } else {
        setMenuOpen(false); // Close the current menu first
        setTimeout(() => {
          setMenuOpen(true); // Open the new menu
          setCurrentMenuType(menuType); // Update the current menu type
        }, 300); // The timeout duration should match the CSS transition duration
      }
    } else {
      setMenuOpen(true); // Open the menu if it was closed
      setCurrentMenuType(menuType); // Update the current menu type
    }
  };

  const signupOptions = () => [
    // {
    // title: "Sign up to create experiences",
    // description: '',
    // link: '/SignUp',
    // enabled: true
    // },
    {
      title: "Sign up to create experiences\n(coming soon)",
      description: 'user accounts are launching soon---try our service as a guest!',
      link: '/',
      enabled: true
      },
    {
    title: "Sign up to provider services\n(coming soon)",
    description: "we're working on enabling proivder accounts, in the meantime sign up to stay updated",
    link: '/Provider',
    enabled: true
    },
    {
    title: "Sign up to streamline your events business\n(coming soon)",
    description: "Business accounts are coming soon--submit your business information to get started.",
    link: '/Business',
    enabled: true
    }
];
// →
const loginOptions = () => [
  // {
  // title: "Sign in to create experiences",
  // description: 'Access your account to quickly find and book event services.',
  // link: '/Login',
  // enabled: true
  // },
  {
    title: "Sign in to create experiences\n(coming soon)",
    description: 'User accounts are coming soon--for now you can try our service without signing in.',
    link: '/',
    enabled: true
    },
  {
  title: "Sign in to provide services\n(coming soon)",
  description: "Provider accounts are coming soon. for now you can sign up as a provider to get updates",
  link: 'Provider',
  enabled: true
  },
  {
  title: "Sign in to streamline your events business\n(coming soon)",
  description: "Business accounts are coming soon. in the meantime, visit our Business and signup!",
  link: '/Business',
  enabled: true
  }
];



  const closeMenu = () => setMenuOpen(false);

  const [currentMenuType, setCurrentMenuType] = useState(null); // Track the current menu type toggleMenu('joinNow')


  const [menuItems, setMenuItems] = useState([]);// NO MENU ITEMS INITIALLY
  const updateMenuItems = (newItems) => setMenuItems(newItems);

  return (
    <GeneralSignUpMenuContext.Provider value={{ isMenuOpen, toggleMenu, closeMenu, menuItems, updateMenuItems, signupOptions,loginOptions }}>
      {children}
    </GeneralSignUpMenuContext.Provider>
  );
};



//ELEMENT
const GeneralSignUpMenu = ({ isOpen, onClose, items}) => {

  const {isMobile, isMedium, isLarge} = useScreenContext()

    const { menuItems } = useGeneralSignUpMenuContext();
    const displayItems = items && items.length > 0 ? items : menuItems;


    useEffect(() => {
        if (isOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
    
        // Cleanup function to remove the style when the component unmounts
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [isOpen]);

  return (
    <GeneralSignUpMenuContainer $isOpen={isOpen} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
        <HeaderPanel>
            <button onClick={onClose}>X</button>
        </HeaderPanel>
        <BodyPanel $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
            {displayItems.map((item, index) => (
            <MenuItem key={index} title={item.title} description={item.description} link={item.link} enabled={item.enabled} />
            ))}
        </BodyPanel>
      {/* Add your signup form or other content here */}
    </GeneralSignUpMenuContainer>
  );
};

export default GeneralSignUpMenu;


//STYLE
const GeneralSignUpMenuContainer = styled.div`
  position: fixed;
  //top: ${NAVBAR_HEIGHT};
  top: 0px;
  right: 0px;
  width: 100%;
  min-width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: ${(props) => (props.$isOpen ? 'translateY(0)' : 'translateY(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index:${Z_INDEX_POPUP_MENUS};
  padding: ${props => {
  if (props.$isMobile) return '5px';
  if (props.$isMedium) return '25px';
  if (props.$isLarge) return '50px';
  return '50px'; // Default to large if none match
}}; 
  display: flex;
  gap : 10px;
  flex-direction: column;
  button{
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5em;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    &:hover{
        background-color: #CCCCCC;
    }
  }
`;

const HeaderPanel = styled.div`
display: flex;
width: 100%;
height: ${NAVBAR_HEIGHT};
justify-content: end;
align-items: center;
`

const BodyPanel = styled.div`
display: flex;
height: auto;
min height: calc(100% - ${NAVBAR_HEIGHT});
width: 100%;
flex-wrap: wrap;
gap: ${props => {
  if (props.$isMobile) return '50px';
  if (props.$isMedium) return '40px';
  if (props.$isLarge) return '30px';
  return '30px'; // Default to large if none match
}}; 
padding: ${props => {
  if (props.$isMobile) return '25px';
  if (props.$isMedium) return '50px';
  if (props.$isLarge) return '100px';
  return '100px'; // Default to large if none match
}}; 
`



// const MenuItems = styled.a`
// display: flex;
// flex-direction: column;
// cursor: pointer;
// border-bottom: 1px solid black;
// flex: 0 0 calc(50% - 50px);


// &:hover{
//     opacity: 0.60;
// }

// h1{
//     font-size: 2.5em;
//     font-weight: 700;
//     color: black;
//     margin-bottom: 0px;
// }
// p{
//     color: #00000060;
// }
// `