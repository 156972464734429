import styled from "styled-components";
import { NAVBAR_HEIGHT } from "components/constants";
import { useNavigate, Link } from 'react-router-dom';
import React, { forwardRef } from 'react';
import { useScreenContext } from 'context/AppContext/ScreenContext';

//this is to get access to popup menu
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

//logo
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one-wht.svg';


const LoginNavBarLogout = ({handleNavigateHome,isScrolledFromTop = true, ...props}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()

    const navigate = useNavigate();

    const navigateHome = () => {
        console.log("YO")
        navigate('/');
        handleNavigateHome();
      }

    const { isMenuOpen, toggleMenu, updateMenuItems } = useGeneralSignUpMenuContext()

    // const handleJoinNowClick = () => {
    //     // Example menu items
    //     const joinNowMenuItems = [
    //         {
    //         title: "Create a user account →",
    //         description: 'coming soon',
    //         link: null,
    //         enabled: false
    //         // link: "/Signup",
    //         },
    //         {
    //         title: "Sign up to Provide Services →",
    //         description: "coming soon",
    //         link: null,
    //         enabled: false
    //         },
    //         {
    //         title: "Sign up for GoghNow Business →",
    //         description: "coming soon",
    //         link: null,
    //         enabled: false
    //         }
    //     ];
    //     updateMenuItems(joinNowMenuItems); // Set the menu items
    //     toggleMenu('join'); // Open the menu
    //   };

    //   const handleLoginNowClick = () => {
    //     // Example menu items
    //     const loginNowMenuItems = [
    //         {
    //         title: "Sign in to create experiences →",
    //         description: 'coming soon',
    //         link: null,
    //         // link: "/Login",
    //         enabled: false
    //         },
    //         {
    //         title: "Sign in to Provide Services →",
    //         description: "coming soon",
    //         link: null,
    //         enabled: false
    //         },
    //         {
    //         title: "Sign in for GoghNow Business →",
    //         description: "coming soon",
    //         link: null,
    //         enabled: false
    //         }
    //     ];
    //     updateMenuItems(loginNowMenuItems); // Set the menu items
    //     toggleMenu('login'); // Open the menu
    //   };

    
    return (
        <Nav $isScrolledFromTop={isScrolledFromTop} {...props}>
            <BIGBrand $isScrolledFromTop={isScrolledFromTop} onClick={navigateHome} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>GOGHNOW</BIGBrand>
        </Nav>
    )
};

const Nav = styled.nav`
// position: fixed;
top : 0;
left: 0;
right: 0; 
height: ${NAVBAR_HEIGHT};
color: black;
display: flex;
justify-content: center;
align-items: center;
padding: 0 36px;
z-index: 100;
width: 100dvw;
max-width: 100vw;
  background-color: ${props => {
    if (props.$isScrolledFromTop) return 'black';
    return 'transparent';
  }};
  transition: background-color 0.3s ease, color 0.3s ease;

`
const BIGBrand = styled.h1`

position:relative;
font-size: 2.5rem;
font-weight: 400;
letter-spacing: ${props => {
    if (props.$isMobile) return '2px';
    if (props.$isMedium) return '4px';
    if (props.$isLarge) return '10px';
    return '10px'; // Default to large if none match
  }};
margin:0px;
text-align:center;
cursor: pointer;
`

const LogoContainer = styled.a`
display: flex;
flex-shrink: 0;
background-color: transparent;
width: auto;
height: 50px;
max-width: 100%;
max-height: 100%;
cursor: pointer;
`

const LogoIMG = styled(GoghNowLogo)`
                       
display: block;
max-height: 100%;
max-width: 150px;
width: auto; /* Ensures image keeps its aspect ratio */
height: auto;
object-fit: contain; /* Ensures the image fits within the container without stretching */
max-height: 50px;

`



export default LoginNavBarLogout;