
//CONSTANTS
import { NAVBAR_HEIGHT } from "components/constants";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

import { useScreenContext } from "context/AppContext/ScreenContext";

const AccountMenu = ({data, ...props}) => {


    const {isMobile, isMedium, isLarge} = useScreenContext()


    const SectionTitle = styled.h2`
    font-size: 1.5em;
    margin-bottom: 10px;
`;

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
`;

const Container = styled.div`
    display: grid;
    width: 100%;
    padding: 10px;
    grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(1, 1fr);';
    if (props.$isMedium) return 'repeat(2, 1fr);';
    if (props.$isLarge) return 'repeat(3, 1fr);';
    return 'repeat(3, 1fr);'; // Default to large if none match
  }};

    gap: 15px;
`;
    const MenuItem = styled(AccountMenuItem)`
    
    `
    const Separator = styled.div`
    height: 1px;
    background: #e0e0e0;
    margin: 10px 0;
  `;
    return (
        <OuterContainer>
            {data.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                    {section.title && <SectionTitle>{section.title}</SectionTitle>}
                    <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        {section.items.map((item, itemIndex) => (
                            <MenuItem
                                key={itemIndex}
                                onClick={item.onClick}
                                title={item.title}
                                description={item.description}
                                imageUrl={item.img}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Container>
                    {sectionIndex < data.length - 1 && <Separator />}
                </div>
            ))}
        </OuterContainer>
    );
}

export default AccountMenu






const AccountMenuItem = ({title, description, imageUrl, ...props}) => {

    async function fetchSvgContent(url) {
        const response = await fetch(url);
        return await response.text();
      }
console.log("imgURL: ",imageUrl)
    return (
        <>
        <Container {...props}>
            <InnerContainer>
                <IconContainer>
                    <img src={imageUrl} alt="icon" />
                </IconContainer>
                <ItemTitle>{title}</ItemTitle>
                <ItemDescription>{description}</ItemDescription>
            </InnerContainer>
        </Container>
        </>
    )
}




const Container = styled.div`
display: flex;
padding-left: 8px;
padding-right: 8px;
flex-direction: column;
width: 100%;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
cursor: pointer;

&:sesshover{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.55);

}
`

const InnerContainer = styled.div`
display: flex;
flex-direction: column;
align-items:start;
padding: 25px 10px;
width: 100%;
height: 100%;
`

const IconContainer = styled.div`
display: flex;
align-items: center;
justify-content: center
height: 50px;
width: 50px;
overflow: hidden;
    img{
        width:100%;
        height:100%;
        display: block;
            &:hover {
        transition: transform 0.65s ease, padding 0.65s ease; /* Smooth animation for transform and padding */
        transform-origin: center center; /* Scale relative to the center */
            transform: scale(0.975); /* Shrink the image by 10% */
        }
    }
        svg{
        width:100%;
        height:100%;
        display: block;
            &:hover {
        transition: transform 0.65s ease, padding 0.65s ease; /* Smooth animation for transform and padding */
        transform-origin: center center; /* Scale relative to the center */
            transform: scale(0.975); /* Shrink the image by 10% */
        }
    }
    // svg{
`

const ItemTitle = styled.p`
font-weight: bold;
color: black;
`
const ItemDescription = styled.p`
color: #00000060;
padding: 0px;
margin:0px;
`