//Hemlet
import { Helmet } from 'react-helmet-async';

//CONSTANTS
import { NAVBAR_HEIGHT } from "components/constants";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//SVG
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one.svg';
import { ReactComponent as GoghNowLogoSM } from 'assets/cta-logo-oneSM.svg';

//Navbar
import NavBarAccount from 'apps/User/NavBarComponents/NavBarAccount';

// //AppNav w cart shit
import { AppNavBarManagerProvider } from "context/AppNavBarContext";

//Sceen
import { useScreenContext } from 'context/AppContext/ScreenContext';

//React
import {useState } from "react";
import { useNavigate, Link } from 'react-router-dom';


import BreadcrumbNavigation from '../BreadcrumbNavigation';
//ACCOUNT MENU
// import AccountMenu from './AccountSettingsPageComponents';

//Icons
// import profileIcon from 'assets/profile-icon.svg';
// import accountIcon from 'assets/account-icon.svg';

const ProfileEdit = ({...props}) => {


    //Navigation
    const navigate = useNavigate();


    const {isMobile, isMedium, isLarge} = useScreenContext()



    return (
        <>
            <Helmet>
                <title>GoghNow the App: Explore Event Services Near You | GoghNow</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="GoghNow | Account Settings | Profil" />
                <meta name="description" content="GoghNow view, update, and modify your profile" />
                <meta property="og:image" content="images/cta-logo-one.png" />
            </Helmet>
            <AppNavBarManagerProvider>
            <SpecialPageContainer>
                <SpecialNavBarLogin/>
                <SpecialPageContentContainer>
                    <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        {(isLarge || isMedium) && (
                            <BreadcrumbNavigation currentPage={'Profile'}/>
                        )}
                        <Heading>Profile</Heading>
                        <FlexContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <EditableContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>

                            </EditableContentContainer>
                            <InstructionsContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>

                            </InstructionsContentContainer>
                        </FlexContentContainer>
                    </Container>
                </SpecialPageContentContainer>
            </SpecialPageContainer>
            </AppNavBarManagerProvider>

        </>
    )
}

export default ProfileEdit

const SpecialPageContainer = styled.div`
display: flex;
flex-direction: column;
height:100dvh;
width: 100dvw;
overflow:hidden;
background-color: white;
`

const SpecialNavBarLogin = styled(NavBarAccount)`
    // position:fixed;
    background-color: transparent !important;
    // top:0px;
`

const SpecialPageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;
align-items: center;
width: 100vw;
`

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
max-width: 1080px;
width:100%;
padding: ${props => {
    if (props.$isMobile) return '5px 5px';
    if (props.$isMedium) return '15px 15px';
    if (props.$isLarge) return '36px 36px';
    return '36px 36px'; // Default to large if none match
  }};
`

const Heading = styled.h1`
color: black;
margin-bottom: 15px;
`


const FlexContentContainer = styled.div`
display: flex;
min-height: 75vh;
`

const EditableContentContainer = styled.div`
display:flex;
// width: calc(100%/ 3 * 2 - 100% / 3 / 4);
width: ${props => {
    if (props.$isMobile) return 'calc(100%)';
    if (props.$isMedium) return 'calc(100%/ 3 * 2 - 50px)';
    if (props.$isLarge) return 'calc(100%/ 3 * 2 - 100% / 3 / 8)';
    return 'calc(100%/ 3 * 2 - 100% / 3 / 8)'; // Default to large if none match
  }};
background-color: #CCC;
min-height:100%;
`

const InstructionsContentContainer = styled.div`
display: flex;
width: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return 'calc(100% / 3 + 40px)';
    if (props.$isLarge) return 'calc(100% / 3)';
    return 'calc(100% / 3)'; // Default to large if none match
  }};
// margin-left: calc(100% / 3 / 4);
margin-left: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '10px';
    if (props.$isLarge) return 'calc(100% / 3 / 8)';
    return 'calc(100% / 3 / 8)'; // Default to large if none match
  }};
background-color: transparent;
min-height:100%;
border: 1px solid #AAA;
border-radius: 15px;
`

const InnerInstructionsContentContainer = styled.div`

`

