// import axios, { AxiosError } from 'axios';
// // import getConfig from 'api/config';
// import { getConfig } from 'api/APIServices';

// const AxiosService = axios.create({
//     baseURL: getConfig(),
//     timeout: 10000,
// });

// export default AxiosService

import axios from 'axios';
// import cookie from 'react-cookies'; // Import react-cookies
import apiUtils from 'api/apiUtils';
// Define the configuration directly here
const config = {
    production: {
        apiBaseUrl: "http://appspot.goghnow/api/",
    },
    development: {
        apiBaseUrl: "http://127.0.0.1:8082/user/",
    },
};

const getConfig = () => {
    const env = process.env.NODE_ENV || 'development';
    return config[env];
};

// Create an instance of axios with base URL
const AxiosService = axios.create({
    baseURL: getConfig().apiBaseUrl,
    timeout: 10000,
});




//ADDED BELOW
// Add Axios request interceptor to include token in the Authorization header
AxiosService.interceptors.request.use(
    (config) => {
        const token = apiUtils.getToken(); // Get token from cookies
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add Axios response interceptor to handle token refresh
AxiosService.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to an expired token
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Prevent infinite retries

            try {
                const refreshToken = apiUtils.getRefreshToken(); // Get refresh token from cookies

                // Refresh the token
                const response = await AxiosService.post('/auth/refresh-token', { refreshToken }); // Update this route if needed
                const newToken = response.token;

                // Update cookies with the new token
                apiUtils.saveToken(newToken);

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return AxiosService(originalRequest);
            } catch (err) {
                console.error('Token refresh failed:', err);
                // Optionally handle logout or redirect to login
            }
        }

        return Promise.reject(error);
    }
);

export default AxiosService;