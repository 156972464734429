import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

import { NAVBAR_HEIGHT, Z_INDEX_NAVBAR } from "components/constants";
import { useNavigate, Link } from 'react-router-dom';
import React, { forwardRef } from 'react';
import { useScreenContext } from 'context/AppContext/ScreenContext';

//this is to get access to popup menu
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

//logo
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one-wht.svg';


const NavBarLogout = ({handleNavigateHome,isScrolledFromTop = true, ...props}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()

    const navigate = useNavigate();

    const navigateHome = () => {
        console.log("YO")
        navigate('/');
        handleNavigateHome();
      }

    const { isMenuOpen, toggleMenu, updateMenuItems, closeMenu, signupOptions, loginOptions } = useGeneralSignUpMenuContext()

    const handleJoinNowClick = () => {
        updateMenuItems(signupOptions); // Set the menu items
        toggleMenu('join'); // Open the menu
      };

      const handleLoginNowClick = () => {
        console.log('loginOptions', loginOptions)
        updateMenuItems(loginOptions); // Set the menu items
        toggleMenu('login'); // Open the menu
      };
    
    return (
        <>
        <Nav $isScrolledFromTop={isScrolledFromTop} {...props}>
            <LogoContainer onClick={navigateHome}>
                {/* <LogoIMG alt="GoghNow Logo"/> */}
                {/* <img src='/images/cta-logo-one-wht.svg' alt="GoghNow" /> */}
            </LogoContainer>
            <BrandNameBigContainer>
                    <BIGBrand $isScrolledFromTop={isScrolledFromTop} onClick={navigateHome} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>GOGHNOW</BIGBrand>            
            </BrandNameBigContainer>
            <NavMenu>
                <NavElementJoinNow onClick={handleJoinNowClick}>
                    {/* <IconContainer>
                        <img src="/images/home-icon.svg" alt="HOME" />
                    </IconContainer> */}
                    <p>JOIN NOW</p>
                </NavElementJoinNow>
                <NavElementLogin onClick={handleLoginNowClick}>
                    {/* <IconContainer>
                        <img src="/images/search-icon.svg" alt="SignIn" />
                    </IconContainer> */}
                    <p>Sign In</p>
                </NavElementLogin>
            </NavMenu>
            {/* <LoginButton>

            </LoginButton> */}
        </Nav>
        <GeneralSignUpMenu isOpen={isMenuOpen} onClose={closeMenu} items = {[]}/>
        </>

    )
};

const Nav = styled.nav`
// position: fixed;
top : 0;
left: 0;
right: 0; 
height: ${NAVBAR_HEIGHT};
color: black;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 36px;
z-index: ${Z_INDEX_NAVBAR};
width: 100dvw;
max-width: 100vw;
  background-color: ${props => {
    if (props.$isScrolledFromTop) return 'black';
    return 'transparent';
  }};
  transition: background-color 0.3s ease, color 0.3s ease;

`

const LogoContainer = styled.a`
   display: flex;
flex-shrink: 0;
background-color: transparent;
width: auto;
height: 50px;
max-width: 100%;
max-height: 100%;
cursor: pointer;
// padding: 0px;
// width: 150px;
// margin-top: 4px;
// max-height: 70px;
// font-size: 0px;
// display: inline-block;
// cursor: pointer;

`

const LogoIMG = styled(GoghNowLogo)`
                       
display: block;
max-height: 100%;
max-width: 150px;
width: auto; /* Ensures image keeps its aspect ratio */
height: auto;
object-fit: contain; /* Ensures the image fits within the container without stretching */
max-height: 50px;

`


const BrandNameBigContainer = styled.div`
position: relative;
display: flex;
flex-grow: 1; /* Allow this to take available remaining space */
  flex-shrink: 1; /* Allow it to shrink if space is tight */
  flex-basis: 0; /* Distribute remaining space equally among flex-grow items */
height: ${NAVBAR_HEIGHT};
width: auto;
// background-color: yellow;
`



const BIGBrand = styled.h1`
  position: absolute;
  font-weight: 400;
  letter-spacing: ${props => {
    if (props.$isMobile) return '2px';
    if (props.$isMedium) return '4px';
    if (props.$isLarge) return '10px';
    return '10px';
  }};
  left: ${props => props.$isScrolledFromTop ? '0%' : '0%'};
  transform: ${props => props.$isScrolledFromTop ? 'translate(0%, 0%)' : 'translate(0%, 0%)'};
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: ${props => props.$isScrolledFromTop ? 'white' : '#F5F5F5'};
  // width: ${props => props.$isScrolledFromTop ? 'auto' : '100vw'};
  // top: ${props => props.$isScrolledFromTop ? '50%' : '70px'};
  margin-top: ${props => props.$isScrolledFromTop ? '0px' : '70px'};
  font-size: ${props => {
  if (props.$isMobile) return props.$isScrolledFromTop ? '6vw' : '15vw';
  if (props.$isMedium) return props.$isScrolledFromTop ? '3vw' : '17vw';
  if (props.$isLarge) return props.$isScrolledFromTop ? '2.5vw' : '17vw';
  return props.$isScrolledFromTop ? '2.5vw' : '17vw'; // Default case
}};
    top: 25%;
    bottom: 25%;

  transition:
    margin-top 0.7s cubic-bezier(1, 0.5, 0.0, 0.25),
    // top 0.7s cubic-bezier(0.5, 0, 0, 1),
    transform 0.7s cubic-bezier(0.5, 0, 0, 1),
    // width 0.7s cubic-bezier(0.5, 0, 0, 1),
    color 0.7s cubic-bezier(0.5, 0, 0, 1),
    font-size 0.7s cubic-bezier(0.5, 0, 0, 1);  /* Transition font-size too */
    left 0.7s cubic-bezier(0.5, 0, 0, 1),
`;
// const BIGBrand = styled.h1`
// position:absolute;
// font-weight: 400;
// letter-spacing: ${props => {
//     if (props.$isMobile) return '2px';
//     if (props.$isMedium) return '4px';
//     if (props.$isLarge) return '10px';
//     return '10px'; 
//   }};
// left: 50%;
// // transform: translate(-50%, 0%);
// margin:0px;
// text-align:center;
// cursor: pointer;
// color: ${props => {
//     if (props.$isScrolledFromTop) return 'white';
//     return '#F5F5F5';
//   }};
// // text-shadow: 0px 2px 2px white;


// width: ${props => {
//     if (props.$isScrolledFromTop) return 'auto';
//     return '100vw';
//   }};

//   top: ${props => {
//     if (props.$isScrolledFromTop) return '50%';
//     return '70px';
//   }};
// transform: ${props => {
//     if (props.$isScrolledFromTop) return 'translate(-50%, -50%) scale(1)';
//     return 'translate(-50%, 0%) scale(5.25)';
//   }};
// margin-top: ${props => {
//     if (props.$isScrolledFromTop) return '0px';
//     return '70px';
//   }};

//   font-size: 2.5vw; // Keep font size constant for proper scaling

//   transition:
//   margin-top 0.7s cubic-bezier(0.5, 0, 0, 1),
//   top 0.7s cubic-bezier(0.5, 0, 0, 1),
//   transform 0.7s cubic-bezier(0.5, 0, 0, 1),
//   width 0.7s cubic-bezier(0.5, 0, 0, 1),
//   color 0.7s cubic-bezier(0.5, 0, 0, 1);
// `

const NavMenu = styled.div`
flex-shrink: 0;
align-items: center;
display: flex;
flex-flow: row nowrap;
height: 100%;
justify-content: flex-end;
margin: 0px;
padding: 0px;
position: relative;
gap: 15px;
// margin-right: auto;
// margin-left: 25px;

// @media (max-width: 768px){
//     display: none;
// }
`

const NavElementLogin = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 25px;
flex-shrink: 0; // Prevents the element from shrinking
flex-grow: 1; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
border-radius: 25px;
border: 2px solid #FBAE62;
color: #FBAE62;
cursor: pointer;
background-color: transparent;

&:hover{
    background-color: #FBAE6260;
}

`

const NavElementJoinNow = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 25px;
flex-shrink: 0; // Prevents the element from shrinking
flex-grow: 1; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
border-radius: 25px;
border: 1px solid transparent;
background-color: #EEE;
color: black;
cursor: pointer;

&:hover{
    background-color: #CCCCCC70;
    color: white;
}
`

const IconContainer = styled.a`
padding: 0px;
width: 50px;
margin-top: 4px;
max-height: 50px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`
export default NavBarLogout;