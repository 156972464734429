import React, { useRef, useEffect, useState } from "react";

import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'
// Import css files
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CartButton from './CartButton'
// import { toggleCartItem } from 'third_party/redux/cart/cartActions';
const  EventServiceImageContainer = ({item, addToCart, removeFromCart, cartItems, toggleCartItem,children, aspectRatio = "8 / 5", ...props}) => {

  const isSelected = cartItems.some(cartItem => cartItem.id === item.id);

  const settings = {
        dots: true, // Add navigation dots for paging
        infinite: true, // Enable continuous sliding
        slidesToShow: 1, // Number of images visible per slide (adjust as needed)
        slidesToScroll: 1, // Number of images to slide on user interaction
        responsive: [
          // Optional responsive settings for different screen sizes
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };



    return (
        <Container $aspectRatio={aspectRatio}>
            <ContainerImageContent {...props}>
            <ImageContainer>

                {item.images.length > 1 ? (
                    <CarouselContainer>
                      <SliderSpecial {...settings} prevArrow={<PrevArrow className="slick-prev"/>} nextArrow={<NextArrow className="slick-next"/>}>
                          {item.images.map((imageItem, index) => (
                            <CarouselImageContainer key={index}>
                                <Image src={imageItem.image} alt={imageItem.alt} />
                                <Gradient $gradient={'linear-gradient(to top, #00000055,#00000015, transparent)'}></Gradient>
                            </CarouselImageContainer>
                          ))}
                      </SliderSpecial>
                    </CarouselContainer>
                ) : (
                        <>
                        <Image src={item.images[0].image} alt={item.images[0].alt} />
                        <Gradient $gradient={'linear-gradient(to top, #00000055,#00000015, transparent)'}></Gradient>
                        </>
                )}
                </ImageContainer>

              
            </ContainerImageContent>

            <ChildrenContainer>
                  {children}
                </ChildrenContainer>


            {/* <ImageSection>
              <ImageContainer>
                <img src={item.images[0].image} alt={item.images[0].alt} />
              </ImageContainer>
            </ImageSection> */}



            <CartButtonSpecial item={item}
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            cartItems={cartItems}
            toggleCartItem={toggleCartItem}/>
          
        </Container> 
    );
    
};

export default EventServiceImageContainer;

const CarouselContainer = styled.div`
  position:relative;
  width: 100%;
  height:100%;
  // margin: 0 auto;  /* Center the slider horizontally */
  // padding: 0 20px;  /* Optional padding around the slider */

`;
const SliderSpecial = styled(Slider)`
width: 100%;
height:100%;

.slick-dots {
    position: absolute; /* Make dots absolute within the carousel */
    bottom: 10px; /* Adjust the bottom position as needed */
    left: 50%; /* Center the dots horizontally */
    transform: translateX(-50%); /* Offset to compensate for centering */
    display: flex; /* Ensure horizontal alignment */
    justify-content: center; /* Center dots within the carousel width */
    li {
        margin: -5px;
        // color: white;
        // background-color:yellow;

        button:before {
        color: white;
        }
    }
  }
  &:hover .prev-button,
  &:hover .next-button {
    opacity: 1;
    color: black;
  }

//   .slick-slide{
//   position:relative;
//   width:100%;
//   height:100%;
// }
  .slick-list{
  height:100%;
  min-height:100%;
    div{
      height:100%;
    }
  }
`
const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  // cursor: pointer;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || "8 / 5"};
`;
const ChildrenContainer = styled.div`
position:absolute;
top:0px;
left:0px;
z-index:1;
height:100%;
width:100%;
`

const CartButtonSpecial = styled(CartButton)`
height:30px;
width: 30px;
z-index:3;
`

// const Container =  styled.div`
//     display: flex;
//     position:relative;
//     height: auto; /* Fills available space within the grid item */
//     width:100%;
//     max-height:auto;
//     max-width:100%;
//     cursor: pointer;
//     `

    const ContainerImageContent = styled.div`
    position: absolute;
    width: 100%; /* Full width of the container */
    height:100%;
    top:0px;
    left:0px;
    overflow:hidden;

  //     img {
  //   display: block;
  //   width: 100%;  /* Fill container horizontally */
  //   height: 100%; /* Fill container vertically */
  //   object-fit: cover; /* Maintain aspect ratio */
  //   object-position: center; /* Center the image */
  //   background-color: #00000040;
  // }
  `;

const ImageContainer = styled.div`
  position: absolute;
  top:0px;
  left:0px;
  height: 100%; /* Match parent height */
  width: 100%; /* Match parent width */
  overflow: hidden;

  img {
    display: block;
    width: 100%;  /* Fill container horizontally */
    height: 100%; /* Fill container vertically */
    object-fit: cover; /* Maintain aspect ratio */
    object-position: center; /* Center the image */
    background-color: #00000040;
  }
`;
const Image = styled.img`
    display: block;
    width: 100%;  /* Fill container horizontally */
    height: 100%; /* Fill container vertically */
    object-fit: cover; /* Maintain aspect ratio */
    object-position: center; /* Center the image */
    background-color: #00000040;
`
const CarouselImageContainer = styled.div`
  position:relative;
  min-height: 100%; /* Match parent height */
  min-width: 100%; /* Match parent width */
  overflow: hidden;
  // background-color: yellow;

  // img {
  //   display: block;
  //   width: 100%;  /* Fill container horizontally */
  //   height: 100%; /* Fill container vertically */
  //   object-fit: cover; /* Maintain aspect ratio */
  //   object-position: center; /* Center the image */
  //   background-color: #00000040;
  // }
`;
const Gradient = styled.div`
position: absolute;
display:block;
width: 100%;
height: 100%;
background-color: ${props => props.$backgroundColor ? `${props.$backgroundColor}` : 'transparent'};  // Corrected the string interpolation
background: ${props => props.$gradient}; /* Apply the gradient dynamically */
z-index:1;
top: 0px;
`

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  
//   display: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    width: 40px;
    height: 40px;
    box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);

  }

  font-weight: 600;
`;

const PrevArrowButton = styled(ArrowButton)`
  left: 10px;
  background-color:white;
  z-index:2;
`;

const NextArrowButton = styled(ArrowButton)`
  right: 10px;
    background-color:white;
      z-index:2;

`;

const PrevArrow = ({ onClick }) => (
  <PrevArrowButton className='prev-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     ←
  </PrevArrowButton>
);

const NextArrow = ({ onClick }) => (
  <NextArrowButton className='next-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     →
  </NextArrowButton>
);