
import styled from 'styled-components';
import {AddButton} from 'components/standard'

// import { useDispatch } from 'react-redux';
// import { toggleCartItem } from 'third_party/redux/cart/cartActions';

//TODO: This should be 
const CartButton = ({ item, addToCart, removeFromCart, cartItems, toggleCartItem, ...props}) => {

    // const dispatch = useDispatch();


    const isInCart = cartItems.some(cartItem => cartItem.id === item.id);

    // const isInCart = cartItems.includes(item);

    const handleClick = (e) => {
        
    e.stopPropagation();
    // dispatch(toggleCartItem(item));
    toggleCartItem(item)
    };


    return (
        <Button onClick={handleClick} $isInCart={isInCart} {...props}>
            {isInCart ? '✓' : '+'}
        </Button>
    );

};

export default CartButton

const Button = styled(AddButton)`
height: 50px;
width: 50px;
font-size: 1.0em;
background-color: ${props => {
    if (props.$isInCart) return 'green';
    if (!props.$isInCart) return 'rgba(251, 174, 98, 0.6)'
    }};
color: ${props => {
    if (props.$isInCart) return 'white';
    if (!props.$isInCart) return 'black'
    }};
}`