import styled from "styled-components";
import { NAVBAR_HEIGHT } from "components/constants";
import { useNavigate, Link } from 'react-router-dom';
import React, { forwardRef } from 'react';
import { useScreenContext } from 'context/AppContext/ScreenContext';

//this is to get access to popup menu
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

//logo
import { ReactComponent as GoghNowLogoWht } from 'assets/cta-logo-one-wht.svg';
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one.svg';


const PlainLogoNavBar = ({handleNavigateHome,isScrolledFromTop = true, ...props}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()

    const navigate = useNavigate();

    const navigateHome = () => {
        console.log("YO")
        navigate('/');
        handleNavigateHome();
      }

      
    
    return (
        <Nav $isScrolledFromTop={isScrolledFromTop} {...props}>
                <BIGBrand>GOGHNOW</BIGBrand>
              {/* <LogoContainer onClick={navigateHome}>
                <GoghNowLogo title="GoghNow Logo" />
              </LogoContainer> */}
        </Nav>
    )
};

const Nav = styled.nav`
// position: fixed;
top : 0;
left: 0;
right: 0; 
height: ${NAVBAR_HEIGHT};
color: black;
display: flex;
justify-content: center;
align-items: center;
padding: 0 36px;
z-index: 100;
width: 100dvw;
max-width: 100vw;
  background-color: ${props => {
    if (props.$isScrolledFromTop) return 'black';
    return 'transparent';
  }};
  transition: background-color 0.3s ease, color 0.3s ease;

`

const LogoContainer = styled.a`
padding: 0px;
width: 150px;
margin-top: 36px;
margin-bottom: 36px;
max-height: 70px;
font-size: 0px;
display: inline-block;
background-color: yellow;
img {
    display: block;
    width:100%;
}
`

const LogoIMG = styled(GoghNowLogo)`
                       
display: block;
max-height: 100%;
max-width: 150px;
width: auto; /* Ensures image keeps its aspect ratio */
height: auto;
object-fit: contain; /* Ensures the image fits within the container without stretching */
max-height: 50px;

`


const BrandNameBigContainer = styled.div`
display: flex;
flex-grow: 1; /* Allow this to take available remaining space */
  flex-shrink: 1; /* Allow it to shrink if space is tight */
  flex-basis: 0; /* Distribute remaining space equally among flex-grow items */
height: ${NAVBAR_HEIGHT};
width: auto;
// background-color: yellow;
justify-content:center;
align-items:center;
`

const BIGBrand = styled.h1`
font-size: 2.5em;
position:relative;
font-weight: 400;
letter-spacing: ${props => {
    if (props.$isMobile) return '2px';
    if (props.$isMedium) return '4px';
    if (props.$isLarge) return '10px';
    return '10px'; // Default to large if none match
  }};
cursor: pointer;
color: ${props => {
    if (props.$isScrolledFromTop) return 'black';
    return 'black';
  }};
  margin:0px;
`
// font-size: ${props => {
//     if (props.$isScrolledFromTop) return '2rem';
//     return '15vw';
//   }};
//   font-size 0.7s cubic-bezier(0.5, 0, 0, 1);

export default PlainLogoNavBar;