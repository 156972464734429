import styled from 'styled-components';
import { NAVBAR_HEIGHT } from 'components/constants';

import { useAppNavBarContext } from "context/AppNavBarContext"


import SearchFunctions from 'utility/searchFunctions';
import { useSearch } from 'third_party/redux/selectors';

const NavBarLoginSM = ({...props}) => {

    const searchCriteria = useSearch().current
    const {setSearchBarState} = useAppNavBarContext()

    const openSearch = () => {
        // openExpandedSearch()
        setSearchBarState('interactive')
        console.log("TEST BRO")
    }

    console.log("NAVBARLOGINsm getting TRIGGERED")
    return (
        <>
            <NavBarContainerSM onClick={() => openSearch()} {...props}>
                <SearchButtonContainer>
                    <SearchButton>
                        <img src="/images/searchicon.png" alt="searchicon" />
                    </SearchButton>
                </SearchButtonContainer>
                <DisplayedContent>
                    <ContentTop>
                        <p>{SearchFunctions.getAddressFromPlace(searchCriteria.location)}</p>
                    </ContentTop>

                    <ContentBottom>
                        <p>{SearchFunctions.displayMonthDayYearTime(searchCriteria.date)} · {SearchFunctions.displayDuration(searchCriteria.duration)} </p>
                    </ContentBottom>

                </DisplayedContent>
            </NavBarContainerSM>
        </>
    )
}
export default NavBarLoginSM

const NavBarContainerSM = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: start;
min-width: 100%;
min-height: ${NAVBAR_HEIGHT}; 
border-radius: 25px;
background-color: white;
box-shadow: 0 0 5px #00000080;
color: black;
z-index: 43219039210;
cursor: pointer;

&:hover {
    box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);
}
`



//SEARCH BUTTON CONTAINER
const SearchButtonContainer = styled.div`
display: flex;
flex-direction: column;
cursor: pointer;
border-radius: 25px;
align-items: end;
justify-content: center;
height:100%;
flex-shrink: 1;
padding: 0px 10px;
`

const SearchButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: calc(${NAVBAR_HEIGHT}/3*2);
width: calc(${NAVBAR_HEIGHT}/3*2);
background-color: rgba(251, 174, 98, 1.0);
border-radius: 50%;
border: none;

img {
  display: block;
  width:50%;
}
`

//DISPLAYED CONTENT
const DisplayedContent = styled.div`
display: flex;
flex-direction: column;
flex-grow: 1;
height: 100%;
align-items: center;
justify-content: center;
`

    const ContentTop = styled.div`
    display: flex;
    flex-direction:row;
    width: 100%;
    height: auto;
    // flex-shrink: 1;
    // flex-grow: 1;
    p{
        margin: 0px;
        font-size: 0.875em;
        color: black;

    }
    `
    const ContentBottom = styled.div`
    display: flex;
    flex-direction:row;
    width: 100%;
    height: auto;
    // flex-shrink: 1;
    // flex-grow: 0;
    
    p{
        margin: 0px;
        font-size: .825em;
        color: #00000080;

    }
    `