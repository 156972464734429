import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

import React, { useEffect, useRef, useState } from "react";



//DELETE THIS ONE
export const PageContainer = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
height: 100dvh;
// width: 100vw;
// width: 100dvw;
background-color: white;
overflow-y: hidden;
overflow-x:hidden;
`


export const GoghNowPageContentContainer = styled.div`
display:inline-block;
// flex-direction: column;
// flex: 1 1;
width: 100vw;
position:relative;
background-color: black;
// height: 100vh;
// overflow-y: auto; /* Enable vertical scrolling */

`


export const PageContentContainer = ({ onScrollStateChangeFromTop, children }) => {
    const [isScrolledFromTop, setIsScrolledFromTop] = useState(false);
    const contentRef = useRef(null);
  
    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY; // Tracks body scroll position
          const isScrolled = scrollPosition > 1;
          if (isScrolled !== isScrolledFromTop) {
            setIsScrolledFromTop(isScrolled);
            if (onScrollStateChangeFromTop) {
              onScrollStateChangeFromTop(isScrolled);
            }
          }
        };
      
        window.addEventListener("scroll", handleScroll);
      
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, [isScrolledFromTop, onScrollStateChangeFromTop]);
  
    return (
      <GoghNowPageContentContainer ref={contentRef}>
        {children}
      </GoghNowPageContentContainer>
    );
  };
  