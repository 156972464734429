import { useSearchActions } from 'third_party/redux/search/searchActions';

//SHIT WE USING
import SearchFunctions from 'utility/searchFunctions';
// import ReactCookie from 'third_party/cookies/ReactCookies';
import apiUtils from 'api/apiUtils';
import { useAuthActions } from 'third_party/redux/auth/authActions';




export const useStateManagementFunctions = () => {
    const { SEARCH_UPDATE } = useSearchActions();
    const { AUTH_LOGIN, AUTH_LOGOUT } = useAuthActions();

    const updateSearchLocation = (place) => {
        const updatedSearch = SearchFunctions.createDictionaryFromSearchCriteria({ place });
        SEARCH_UPDATE(updatedSearch);
    };

    const logout = () => {
        apiUtils.deleteTokens(); // Delete token and refresh token
        AUTH_LOGOUT(); // Call logout action
    };

    const login = (token, refresh_token, user_type = 'user') => {
        apiUtils.saveTokens(token, refresh_token); // Save tokens
        AUTH_LOGIN({ user_type }); // Dispatch login action
    };

    return {
        updateSearchLocation,
        logout,
        login,
    };
};
// class StateManagementFunctions {

    

//     static UpdateSearchLocation = (place) => {


        
//         const updatedSearch = SearchFunctions.createDictionaryFromSearchCriteria({ place });
//         SEARCH_UPDATE(updatedSearch);
//     }

//     static Logout = () => {

//         //Remove Cookies
//         apiUtils.deleteTokens() //delete token and refreshToken

//         //Remove Logout Status
//         const {AUTH_LOGOUT} = useAuthActions()
//         AUTH_LOGOUT()

//     }

//     static Login = (token,refresh_token, user_type = 'user') => {

//         //get refreshtoken and everything else
//         apiUtils.saveTokens(token, refresh_token)

//         //Login in the auth joint
//         let params = {'user_type':user_type}
//         const {AUTH_LOGIN} = useAuthActions(params)

//     }

// }

// export default StateManagementFunctions
// export const METHOD_LOGIN_AS_GUEST_WITH_PLACE = (place) => {

//     //NEW USE & AUTH
//     const {USER_UPDATE} = useUserActions()
//     const {AUTH_LOGIN_GUEST} = useAuthActions()
//     const {SEARCH_UPDATE} = useSearchActions()
    
//     const userUpdate = {lat:place.lat, lng:place.lng}
//     USER_UPDATE(userUpdate)
//     SEARCH_UPDATE({location:place})
//     AUTH_LOGIN_GUEST() //logs in
// }

// This is a custom hook where hooks are allowed
//METHOD_LOGIN_AS_GUEST_WITH_PLACE
// export const useGuestLoginWithPlace = () => {
    
//     //Navigate
//     const navigate = useNavigate();

//     //Redux Items to Update
//     const { USER_UPDATE } = useUserActions();
//     const { AUTH_LOGIN_GUEST } = useAuthActions();
//     const { SEARCH_UPDATE } = useSearchActions();
    
//     const loginAsGuestWithPlace = (place) => {
//         const userUpdate = { lat: place.lat, lng: place.lng };

//         USER_UPDATE(userUpdate); // updates user info
//         SEARCH_UPDATE({ location: place }); // updates search info
//         AUTH_LOGIN_GUEST(); // logs in as guest
//         navigate('/s');
//     };

//     return loginAsGuestWithPlace; // return the function for use in components
// };