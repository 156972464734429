import React from 'react';

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

// Grid Section with dynamic layout
const GridSection = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => props.columns || 'repeat(4, 1fr)'};
  grid-template-rows: ${(props) => props.rows || 'repeat(3, 1fr)'};
  gap: ${(props) => props.gap || '15px'};
  padding-right: ${(props) => props.gap || '15px'};
  overflow: hidden;
`;

// GridItem: Container for both media and text
const GridItem = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #AAA;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  grid-column: ${(props) => props.gridColumn || 'span 1'};
  grid-row: ${(props) => props.gridRow || 'span 1'};
  // Ensure we do not pass these as plain DOM props
`;

// Styled Image
const GridItemImage = styled.img`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover; // Ensures the image fills the space without distorting
  object-position: center; // Ensures the image is centered within its container
  // border-radius: 8px;
  overflow: hidden;

  // Dynamically apply grid column and row values
  // grid-column: ${(props) => props.gridColumn || 'span 1'};
  // grid-row: ${(props) => props.gridRow || 'span 1'};
`;

// Styled Video
// const GridItemVideo = styled.video`
//   width: 100%;
//   height: 100%;
//   min-width: 100%;
//   min-height: 100%;
//   object-fit: cover;
//   object-position: center;
//   overflow: hidden;
// `;

const GridItemVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border: none; // Ensure no outline or border appears
  outline: none;
  display: block; // Prevent it from popping out of its container
`;

// Caption or Text Inside the Grid Item
const GridItemText = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.6); // Semi-transparent background for text visibility
  padding: 5px;
  border-radius: 4px;
`;

// List of file extensions for images and videos
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv', 'flv'];

const isVideo = (src) => {
  const ext = src.split('.').pop().toLowerCase(); // Extract the file extension
  return videoExtensions.includes(ext);
};




const ImageGridInteractive = ({ items, columns, rows, gap, ...props }) => {

  React.useEffect(() => {
    const videos = document.querySelectorAll('video');
    videos.forEach((video) => {
      video.muted = true; // Ensure it's muted
      video.play().catch(() => {
        console.log('Autoplay failed on mobile');
      });
    });
  }, []);


  return (
    <GridSection columns={columns} rows={rows} gap={gap} {...props}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <GridItem
            gridColumn={item.gridColumn}
            gridRow={item.gridRow}
          >
            {isVideo(item.src) ? (
              <GridItemVideo
                src={item.src}
                autoPlay
                loop
                muted
                playsInline
              />
            ) : (
              <GridItemImage
                src={item.src}
                alt={`item-${index}`}
              />
            )}
            {item.caption && <GridItemText>{item.caption}</GridItemText>}
          </GridItem>
        </React.Fragment>
      ))}
    </GridSection>
  );
};

export default ImageGridInteractive;
