import React from "react";
import {useState, useEffect, useRef } from "react";

//Helmet
import { Helmet } from 'react-helmet-async';

//Compoennts two
import { Link } from "react-router-dom";
import { blogs } from "./blogsData";
import { StandardBlog } from "components/Blogs/BlogPreview";

//components
import {PageContentContainer} from "components/Page/Elements"
import FooterLoggedOut from 'components/Footer/FooterLoggedOut';
import NavBarLogoutGeneral from "components/navbar/NavBarLogoutGeneral";

// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'


import BlogGrid from "components/Blogs/BlogGrid";
import { SECTION } from "components/standard";


import { useScreenContext } from 'context/AppContext/ScreenContext';

//Screen Contenxt


  //Page 1 Layout (4 Rows x 3 Columns = 12 Slots)
//   const sizes = [
//     { type:'header', gridColumn:' 1 / span 2', gridRow:'1 / span 1'}, // Larger image
//     { type:'standard', gridColumn: '3/ span 1', gridRow: '1 / span 1' },
//   ];

const BlogHome = () => {


    // columns="repeat(3, 1fr)" rows="repeat(2, 1fr)"
    const {isMobile, isMedium, isLarge} = useScreenContext()



    const contentRef = useRef();
    const scrollToTop = () =>{
        console.log("this is working?")
        // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
      } 

  return (
    <>
    <Helmet>
        <title>The Events Blog | Insights & Inspiration from GoghNow: Leaders in Event Services</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="The Events Blog | Insights & Inspiration from GoghNow: Leaders in Event Services" />
        <meta name="description" content="Discover expert insights, industry trends, and actionable tips for bringing unforgettable events to life. The Events Blog by GoghNow shares thought leadership, technology, founder perspectives, and the latest in the event services space, empowering you to create exceptional experiences with ease." />
        <meta property="og:image" content="images/cta-logo-one.png" />
    </Helmet>
    <SpecialNavBarLogout handleNavigateHome={scrollToTop}/>
    <SpecialPageContentContainer ref={contentRef}>

    <BlogGridSection>
        <ContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
            <BlogTitle>The Events Blog <span>by GoghNow</span></BlogTitle>
            <BlogDescription>Your guide to effortless event creation—delivering expert insights, industry trends, innovative ideas, and the latest in technology.</BlogDescription>
            <HorizontalLine/>
            <BlogGridContainer>
                <BlogGrid blogs={blogs} gap="25px" layout="custom1"></BlogGrid>
            </BlogGridContainer>
        </ContentContainer>
    </BlogGridSection>

    {/* <div style={styles.gridContainer}>
      {blogs.map((blog) => (
        <Link to={`/blog/${blog.id}`} key={blog.id} style={styles.link}>
          <StandardBlog
            heading={blog.heading}
            subheading={blog.subheading}
            date={blog.date}
          >
            <p>{generatePreview(blog.content)}</p>
          </StandardBlog>
        </Link>
      ))}
    </div> */}

    <FooterLoggedOut></FooterLoggedOut>
    </SpecialPageContentContainer>
    </>


  );
};

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "16px",
    padding: "16px",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
};

export default BlogHome;




  const SpecialNavBarLogout = styled(NavBarLogoutGeneral)`
    position:fixed;
    background-color: transparent !important;
`

const SpecialPageContentContainer = styled(PageContentContainer)`
background-color: #F5F5F5 !important;
`

const BlogGridSection = styled(SECTION)`

// overflow: hidden;
display: flex;
flex-direction: column;
text-align: left;
// min-height: 100vh;
width: 100%;
justify-content: start;
align-items: center;
background-color:#F5F5F5;
`
const ContentContainer = styled.div`
width: ${props => {
    if (props.$isMobile) return '98%';
    if (props.$isMedium) return '90%';
    if (props.$isLarge) return '80%';
    return '80%'; // Default to large if none match
  }};
display: flex;
flex-direction: column;
`

const BlogTitle = styled.h1`
color:black;
margin-bottom: 0px;

span {
    font-weight: bold;
    color: orange; // Style the span as needed
    font-size: .25em;
    padding: 0px;
    margin: 0px;
    height: auto;
    display: inline-flex;
  }
`

const BlogDescription = styled.h3`
color:#00000060;
margin-top: 5px;
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 3px;
  background-color: #AAAAAA;
  margin: 25px 0;
`;

const BlogGridContainer = styled.div`
width: 100%;
height: auto;
display: flex;
`