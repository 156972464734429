import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; /*Switch is now Routes*/
// import Home from "./pages/home/Home"

//User
import Home from 'pages/home/Home';
//User App
import UserApp from 'apps/User/UserApp';
import Book from "./pages/book/Book"

//Provider
import Provider from "./pages/provider/Provider"

//SignUp/Login
import Signup from "./pages/signup/Signup"

import Login from "./pages/login/Login"

//Business
import Business from './pages/business/Business';
import BusinessApp from 'apps/BusinessApp';

//Careers
import BizDevRep from 'pages/careers/biz-dev-rep-I';

//Embedded Test
import Embedded from "./embedded/Embedded"
 
import './App.css';

import { SkeletonTheme } from 'react-loading-skeleton';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import BlogHome from 'pages/blogs/BlogHome';
import BlogPost from 'components/Blogs/BlogPost';

//For TOKEN TESTING
import apiUtils from 'api/apiUtils';
import { useStateManagementFunctions } from 'third_party/redux/stateManagementFunctions';
// import { refreshTokenURL } from 'api/User/AuthService';
import AuthServiceAPI from 'api/User/AuthService';


import AccountSettingsPage from 'apps/User/AccountSettings/AccountSettingsPage';
import ProfileEdit from 'apps/User/AccountSettings/Profile/Profile';
import AccountEdit from 'apps/User/AccountSettings/Account/Account';

function App() {

  const { logout } = useStateManagementFunctions(); // Get the logout function from the custom hook

  useEffect(() => {
    // Retrieve the refresh token
    const refreshToken = apiUtils.getRefreshToken();

    if (!refreshToken) {
      // If no refresh token, log out
      // const {logout} = useStateManagementFunctions()
      logout();
      console.log('token was not found and we logged out')
    } else {
      // Optional: Refresh the token here if needed
      console.log("Refresh token found:", refreshToken);
      const response = AuthServiceAPI.refresh_token(refreshToken) // Update this route if needed
      console.log('token WAS found and we logged IN')

    }
  }, []);
  
  return (
    <div className="App">
      <SkeletonTheme baseColor="#CCC" highlightColor='#EEE'>
        {/* <AppProvider> */}
        {/* <AuthProvider>
          <UserProvider> */}
            <Router>
              <ScrollToTop/>
              <Routes>
                {/* USER */}
                {/* ------------ */}

                {/* HOME */}
                <Route path="/" element={<Home />} />
                {/* SEARCH */}
                <Route path="/s" element={<UserApp />} />
          
                {/* {ACCOUNT SETTINGS} */}
                {/* <Route path="/account-settings" element={<AccountSettingsPage />} />
                <Route path="/account-settings/profile" element={<ProfileEdit />} />
                <Route path="/account-settings/account" element={<AccountSettingsPage />} /> */}

                {/* SIGNUP */}
                {/* <Route path="/signup" element={<SignupWithEmail />} /> */}
                {/* <Route path="/signup" element={<Signup />} /> */}

                {/* LOGIN */}
                {/* <Route path="/login" element={<Login />} /> */}
                {/* BOOKING PAGE */}
                <Route path="/book" element ={<Book />} />


                {/* PROVIDER */}
                {/* ------------ */}
                <Route path="/provider" element={<Provider />} />


                {/* BUSINESS */}
                {/* ------------ */}

                {/* BUSINESS PAGE */}
                <Route path="/business" element ={<Business />} />
                {/* BUSINESS APP */}
                {/* <Route path="/business/app" element ={<BusinessApp />} /> */}


                {/* BLOG */}
                {/* ------------ */}

                {/* BLOG STUFF */}
                <Route path="/blog" element={<BlogHome />} />
                {/* <Route path="/Blog/:id" element={<BlogPost />} /> */}
                <Route path="/blog/:heading" element={<BlogPost />} />


                {/* Career Paths */}
                <Route path="/careers/biz-dev-rep-I" element={<BizDevRep />} /> {/* Add this route */}


                {/* EMBEDDED IDK WHAT THIS IS */}
                {/* <Route path="/embedded" element ={<Embedded />} /> */}


              </Routes>
            </Router>
          {/* </UserProvider>
        </AuthProvider> */}
        {/* </AppProvider> */}
      </SkeletonTheme>
    </div>
  );
}

export default App;


//SCROLL TO TOP WHEN NAVIGATING

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const html = document.documentElement;

    // Store the original scroll behavior to restore later
    const originalScrollBehavior = html.style.scrollBehavior;

    // Temporarily disable smooth scrolling for path change
    html.style.scrollBehavior = 'auto';

    // Jump to the top instantly without smooth scrolling
   //window.scrollTo(0, 0); // or use this
    document.documentElement.scrollTop = 0;

    // Restore the original scroll behavior after path change
    html.style.scrollBehavior = originalScrollBehavior;
  }, [pathname]);
  return null; // No UI element is rendered
};

